import React from "react";
import TimeSheetListItem from "./TimeSheetListItem";
import {
  baseURLImage,
  getHrAndMinsFormatting,
  getNameProfile,
} from "Utils/utils";
import { ImBlocked } from "react-icons/im";

const TimeSheetList = (props) => {
  const {
    timeSheetData,
    selectedTimeSheet,
    setSelectedTimeSheetLocally,
    handleScrollTop,
  } = props;

  const handleImageError = (e, fname, lname) => {
    return (e.target.src = getNameProfile(fname, lname));
  };

  const isLieInTimeSheet = (timesheet) => {
    const ttlTimeSheets = [
      ...timesheet.pendingTimeSheets,
      ...timesheet.approvedTimeSheets,
      ...timesheet.otherTimeSheets,
    ];
    if (ttlTimeSheets && selectedTimeSheet) {
      let isLie = false;
      ttlTimeSheets.forEach((x) => {
        if (x && x.uuid === selectedTimeSheet.uuid) {
          isLie = true;
          return;
        }
      });
      return isLie;
    }
    return false;
  };

  return (
    <>
      {timeSheetData && timeSheetData.length > 0 ? (
        timeSheetData.map((timeSheet, index) => (
          <div
            key={index}
            className={`${
              isLieInTimeSheet(timeSheet?.timeSheet)
                ? "bg-[#EFF8FF] border-[#c3dcef]"
                : ""
              // : "bg-transparent profile-bg-hover hover:border-gray-400 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-purple-500"
            } rounded-[12px] py-5 mt-2 border`}
          >
            <div className='mb-1 bg-profile group w-full bg-gray-100 rounded-md px-3.5 py-2 text-sm text-left font-medium text-gray-700'>
              <div className='flex w-full justify-between items-center'>
                <div className='flex min-w-0 items-center justify-between space-x-3'>
                  <img
                    className='w-12 h-12 bg-gray-300 rounded-full flex-shrink-0 object-cover'
                    src={
                      timeSheet.photo &&
                      !timeSheet.photo.includes("www.pngitem.com")
                        ? baseURLImage(timeSheet.photo)
                        : getNameProfile(
                            timeSheet.first_name ?? " ",
                            timeSheet.last_name ?? " "
                          )
                    }
                    alt='avatar'
                    onError={(e) =>
                      handleImageError(
                        e,
                        timeSheet.first_name ?? "  ",
                        timeSheet.last_name ?? " "
                      )
                    }
                  />
                  <span className='flex-1 flex flex-col min-w-0'>
                    <span className='sheet-user-name text-black-900 text-sm font-medium truncate capitalize'>
                      {`${timeSheet.first_name} ${timeSheet.last_name}`}
                    </span>
                    <span className='sheet-user-username text-black-500 text-sm truncate lowercase'>
                      {`${
                        timeSheet?.timeSheet?.pendingTimeSheets.length +
                        timeSheet?.timeSheet?.approvedTimeSheets.length +
                        timeSheet?.timeSheet?.otherTimeSheets.length
                      } ${
                        timeSheet?.timeSheet?.pendingTimeSheets.length +
                          timeSheet?.timeSheet?.approvedTimeSheets.length +
                          timeSheet?.timeSheet?.otherTimeSheets.length >
                        1
                          ? "timesheets"
                          : "timesheet"
                      } | ${
                        timeSheet?.timeSheet.ttlTimesheetSeconds &&
                        getHrAndMinsFormatting(
                          timeSheet?.timeSheet.ttlTimesheetSeconds
                        )
                      }`}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            {timeSheet?.timeSheet && (
              <TimeSheetListItem
                isLieInTimeSheet={isLieInTimeSheet(timeSheet?.timeSheet)}
                setSelectedTimeSheetLocally={(i) => {
                  setSelectedTimeSheetLocally(i);
                  handleScrollTop();
                }}
                timeSheet={timeSheet.timeSheet}
                selectedTimeSheet={selectedTimeSheet}
                key={index}
              />
            )}
          </div>
        ))
      ) : (
        <div className='h-full text-red-500 flex justify-center items-center space-x-2'>
          <ImBlocked />
          <span className='inline-block text-red-500'>No Data found!</span>
        </div>
      )}
    </>
  );
};

export default TimeSheetList;
