import React, { Fragment, useContext, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import LoaderButton from "../common/LoaderButton/LoaderButton";
import { ExclamationIcon } from "@heroicons/react/outline";
import { callLogout } from "../../store/actions/auth.actions";
import { useDispatch, useSelector } from "react-redux";
import { getLogoutLoading } from "../../store/selector/auth.selector";
import { LOADER_STATUS } from "Constants/constant";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { LoginContext } from "helpers/LoginContext";

const LogoutModel = (props) => {
  const { isOpen, closeModal } = props;

  const { unSubscribeChannels } = useContext(LoginContext);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoading = useSelector(getLogoutLoading);


  const [logoutRenderLoader, setLogoutRenderLoader] = useState(
    LOADER_STATUS.NA
  );

  useEffect(() => {
    if (!isLoading && logoutRenderLoader !== LOADER_STATUS.NA) {
      navigate("/");
    }
  }, [isLoading]);

  const handleAuthLogout = () => {
    setLogoutRenderLoader(LOADER_STATUS.PENDING);
    unSubscribeChannels();
    dispatch(callLogout());
    navigate("/");
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as='div'
        onClose={closeModal}
        className='fixed inset-0 z-50 top-50 overflow-y-auto'
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>
        <div className='fixed z-10 inset-0 overflow-y-auto'>
          <div className='flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6'>
                <div className='sm:flex sm:items-start'>
                  <div className='mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10'>
                    <ExclamationIcon
                      className='h-6 w-6 text-red-600'
                      aria-hidden='true'
                    />
                  </div>
                  <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                    <Dialog.Title
                      as='h3'
                      className='text-lg leading-6 font-medium text-gray-900'
                    >
                      You're about to log out
                    </Dialog.Title>
                    <div className='mt-2'>
                      <p className='text-sm text-gray-500'>
                        Are you sure you want to log out of the system?
                      </p>
                    </div>
                  </div>
                </div>
                <div className='mt-5 sm:mt-4 sm:flex'>
                  {isLoading ? (
                    <LoaderButton
                      classes='inline-flex justify-center items-center px-4 py-2 text-sm font-medium text-white bg-red-500 border border-red-500 hover:border-red-400 hover:bg-red-400 rounded-md focus:outline-none cursor-block'
                      svgClasses='inline mr-3 w-4 h-4 text-white animate-spin'
                      textbutton='Loading...'
                    />
                  ) : (
                    <button
                      type='button'
                      className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm'
                      onClick={handleAuthLogout}
                      disabled={isLoading}
                    >
                      Yes, please
                    </button>
                  )}
                  <button
                    type='button'
                    className='ml-2 mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm'
                    onClick={closeModal}
                  >
                    Ah, nevermind!
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default LogoutModel;
