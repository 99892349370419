import React, { useState, useMemo, useEffect } from "react";
import CircularLoader from "../../common/CircularLoader/CircularLoader";
import {
  apiGetRequest,
  addFiltersAndPagination,
  apiPostRequest,
} from "../../../helpers/Requests";
import { BiPlus } from "react-icons/bi";
import PaginationComponent from "../../common/Pagination/index";
import CustomButton from "../../common/Button/CustomButton";
import Search from "../../common/Search/Search";
import {
  emptyPaginationObj,
  PARENT_FORM_CATEGORY_SLUG,
  PARTICIPANT_LIST_LIMIT,
  SORT_TYPE,
  USER_ROLE,
} from "../../../Constants/constant.js";
import {
  DATA_FORMATTER_FOR_DYNAMICS_FORMS,
  getPaginationObj,
  getUrlRole,
  selectedTabState,
  SilHomeselectedTabState,
} from "../../../Utils/utils.js";
import ModalSkeleton from "../ModalSkeleton";
import _ from "lodash";
import { useSelector } from "react-redux";
import { getSelectedTabSelector } from "../../../store/selector/auth.selector";
import ActionsForSubmitPtForms from "./ActionsForSubmitPtForms";
import apiCall from "store/services/sp.services";
import { toast } from "react-toastify";
import SelectOption from "components/common/Select/Select";
import { roleAccess } from "helpers/RolesPermission";
import CheckBox from "components/common/checkBox/checkbox";
import { MdMarkEmailRead } from "react-icons/md";
import MultipleEmailModal from "components/DynamicForms/ViewFormTemplate/MultipleEmailModal";
import {
  URL_FORM_SEND_EMAIL_FOR_SP,
  URL_SIL_ACCOMMODATION_FORM_LIST_BY_CATEGORY,
} from "store/services/URL";
import { getUser } from "store/selector/auth.selector";

const CategoryFormsListing = (props) => {
  const {
    buttonText,
    slug,
    particpantUuid,
    role = null,
    participantId,
  } = props;
  const [toggleState, setToggleState] = useState(selectedTabState.get());
  const selectedTabSelector = useSelector(getSelectedTabSelector);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [search, setSearch] = useState(null);

  const [supportPlans, setSupportPlans] = React.useState([]);
  const [paginationObj, setPaginationObj] = useState(emptyPaginationObj);
  const [request, setRequest] = React.useState({
    isRequested: false,
    isSuccess: false,
    isFailed: false,
  });
  const [sortBy, setSortBy] = useState(paginationObj.sort_field);
  const [sortDescending, setSortDescending] = useState(
    paginationObj.sort_order === SORT_TYPE.DESC ? true : false
  );
  const [allSelected, setAllSelected] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
  const [isShowMultipleEmail, setIsShowMultipleEmail] = useState(false);
  const [emailSendLoader, setEmailLoader] = useState(false);
  const currentLogedInUser = useSelector(getUser);
  const [selectedFormForEmail, setSelectedFormForEmail] = useState(null);
  useEffect(() => {
    // alert("hi this");

    const tabStateHandler =
      role === "sil" ? SilHomeselectedTabState : selectedTabState;

    const currentTab = tabStateHandler.get();
    if (
      currentTab &&
      currentTab.hasOwnProperty("isModalOpen") &&
      currentTab.hasOwnProperty("slug") &&
      currentTab.slug === slug
    ) {
      setIsModalOpen(true);
      fetchFormTempletes(`${slug}`, { page: 1 });
      delete currentTab.isModalOpen;
      tabStateHandler.save(JSON.stringify(currentTab));
      setSearch(null);
    }
  }, [
    selectedTabState.get(),
    SilHomeselectedTabState.get(),
    slug,
    selectedTabSelector,
  ]);

  const fetchFormTempletes = async (slug, filters) => {
    // console.log("filters", filters);
    if (!filters || filters === undefined) {
      Object.assign((filters = {}), {
        page: paginationObj.page,
      });
    }
    Object.assign(filters, { per_page: PARTICIPANT_LIST_LIMIT });
    setRequest({ ...request, isRequested: true });
    try {
      const silUrl = addFiltersAndPagination(
        URL_SIL_ACCOMMODATION_FORM_LIST_BY_CATEGORY,
        filters
      );
      const url = addFiltersAndPagination(
        `${getUrlRole()}/form-templates-by`,
        filters
      );
      const urlHrm = addFiltersAndPagination(
        `${getUrlRole()}/hrm/form-templates-by`,
        filters
      );
      let res = await apiGetRequest(
        `${
          role === "sil" ? silUrl : role === "hrm" ? urlHrm : url
        }&cat_slug=${slug}${
          filters.hasOwnProperty("sub_cat_uuid") &&
          filters.sub_cat_uuid !== "all"
            ? `&sub_cat_uuid=${filters.sub_cat_uuid}`
            : ""
        }`
      );
      if (res.status !== 200) {

        setRequest({ ...request, isRequested: false, isFailed: true });
        console.log("Something Went Wrong");
      } else if (res.status === 200) {
        setAllSelected(false)
        setCheckboxes([])
        setRequest({ ...request, isRequested: false, isSuccess: true });
        if (slug === PARENT_FORM_CATEGORY_SLUG.PARTICIPANT_ASSESSMENT.SLUG) {
          setSupportPlans(res?.data?.data?.forms);
        } else setSupportPlans(res?.data?.data?.forms);
        setPaginationObj(
          getPaginationObj(res.data.data.forms, res.data.data.query_params)
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSearch(null);
    setAllSelected(false)
    setCheckboxes([])
    if (toggleState?.hasOwnProperty("isModalOpen")) {
      const obj = {
        name: toggleState.name,
        id: toggleState.id,
        children: toggleState.children,
      };
      setToggleState(obj);
      selectedTabState.save(JSON.stringify(obj));
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
    fetchFormTempletes(`${slug}`);
  };

  useEffect(() => {
    setSortBy(paginationObj.sort_field ? paginationObj.sort_field : null);
    if (paginationObj.sort_order)
      setSortDescending(
        paginationObj.sort_order
          ? paginationObj.sort_order === SORT_TYPE.DESC
            ? true
            : false
          : false
      );
  }, [paginationObj]);

  useEffect(() => {
    const obj = { page: 1 };
    if (sortBy) {
      Object.assign(obj, {
        sort_field: paginationObj.sort_field ? paginationObj.sort_field : null,
        sort_order:
          paginationObj.sort_order === SORT_TYPE.DESC
            ? SORT_TYPE.DESC
            : SORT_TYPE.ASC,
      });
    }
    if (!search) handleFetch({ ...obj });
  }, []);

  const handleFetch = (obj = {}) => {
    const filter = _.cloneDeep(obj);
    if (paginationObj.page) Object.assign(filter, { page: paginationObj.page });
    if (paginationObj.filter && !obj.hasOwnProperty("status")) {
      Object.assign(filter, { filter: paginationObj.filter });
    }
    if (paginationObj.sort_field && !obj.hasOwnProperty("sort_field")) {
      Object.assign(filter, {
        sort_field: paginationObj.sort_field ? paginationObj.sort_field : null,
        sort_order:
          paginationObj.sort_order === SORT_TYPE.DESC
            ? SORT_TYPE.DESC
            : SORT_TYPE.ASC,
      });
    }
    if (obj.hasOwnProperty("search")) {
      Object.assign(filter, { page: 1, search: obj.search });
    }
    if (paginationObj.search && !obj.hasOwnProperty("search")) {
      Object.assign(filter, { search: paginationObj.search });
    }
    if (obj.status !== "both" && obj.hasOwnProperty("status")) {
      Object.assign(filter, {
        filter: {
          status: [obj.status],
        },
      });
    }
    if (obj.hasOwnProperty("page")) Object.assign(filter, { page: obj.page });
    if (obj.hasOwnProperty("sub_cat_uuid")) {
      Object.assign(filter, { sub_cat_uuid: obj.sub_cat_uuid });
    }
    if (isModalOpen) fetchFormTempletes(`${slug}`, { ...filter });
  };

  useEffect(() => {
    if (search === null) return;
  const timeOutId = setTimeout(
      () =>
        handleFetch({
          search: search,
          sub_cat_uuid: selectedSubCat?.value ? selectedSubCat?.value : "all",
        }),
      1000
    );
    return () => clearTimeout(timeOutId);
  }, [search]);

  const supportPlanToMap = useMemo(() => {
    if (
      supportPlans &&
      supportPlans.data &&
      (slug === PARENT_FORM_CATEGORY_SLUG.PARTICIPANT_ASSESSMENT.SLUG ||
        slug === PARENT_FORM_CATEGORY_SLUG.MEDICATION.SLUG)
    ) {
      return supportPlans.data.sort((a, b) =>
        a.sequence < b.sequence ? -1 : 1
      );
      // .sort((a, b) => (a.is_mandatory === "Mandatory" ? -1 : 1));
    } else if (supportPlans && supportPlans.data) {
      return supportPlans.data;
    }
    return [];
  }, [supportPlans]);

  const [fetchSubFormCatsLoader, setFetchSubFormCatsLoader] = useState(false);
  const [formCategories, setFormCategories] = useState([]);
  const [selectedSubCat, setSelectedSubCat] = useState({
    label: "All",
    value: "all",
  });

  const fetchCategories = async () => {
    setFetchSubFormCatsLoader(true);
    try {
      const res = await apiCall.formCategoriesRequest({ role });
      const data = res.form_categories.map((x) => {
        const obj = {
          label: x.name,
          value: x.id,
        };
        return { ...x, ...obj };
      });
      setFormCategories([...data]);
      setFetchSubFormCatsLoader(false);
    } catch (err) {
      setFetchSubFormCatsLoader(false);
      toast.error(err.message);
    }
  };

  const getSubCategoriesByParentSlug = (val) => {
    if (!val) return [{ label: "All", value: "all" }];

    const customOrder = [
      { name: "Bowel Care Mgt", order: 1 },
      { name: "Complex Bowel ", order: 2 },
      { name: "Complex Wound", order: 3 },
      { name: "Disaster Mgt", order: 4 },
      { name: "Enteral Feed Mgt", order: 5 },
      { name: "Severe Dysphagia Mgt", order: 6 },
      { name: "Subcutaneous Injections", order: 7 },
      { name: "Tracheostomy Mgt", order: 8 },
      { name: "Urinary Catheter Mgt", order: 9 },
    ];

    if (formCategories?.length > 0) {
      const data =
        formCategories
          .find((X) => X.slug === val)
          ?.form_sub_categories?.map((item) => ({
            ...item,
            label: item.name,
            value: item.uuid,
          }))
          ?.sort((a, b) => {
            const orderA =
              customOrder.find(
                (category) =>
                  category.name.toLowerCase() === a.name.toLowerCase()
              )?.order || Infinity;
            const orderB =
              customOrder.find(
                (category) =>
                  category.name.toLowerCase() === b.name.toLowerCase()
              )?.order || Infinity;
            return orderA - orderB;
          }) || [];
      data?.unshift({ label: "All", value: "all" });
      return data;
    }
    return [{ label: "All", value: "all" }];
  };

  useEffect(() => {
    if (
      supportPlanToMap &&
      checkboxes &&
      checkboxes?.length === supportPlanToMap?.length &&
      supportPlanToMap?.length !== 0
    ) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [checkboxes]);
  useEffect(() => {
    if (!roleAccess([USER_ROLE.Sp_Standard_User])) {
      fetchCategories();
    }
    return () => {
      setSelectedSubCat(null);
      setFormCategories([]);
    };
  }, []);

  const toggleCheckboxes = (val) => {
    setCheckboxes(val ? supportPlanToMap?.map((el) => el) : []);
  };

  const handleCheckboxChange = (e, item) => {
    const filteredList = checkboxes?.filter((c) => c.id !== item.id);
    if (e) {
      filteredList.push(item);
    }
    setCheckboxes(filteredList);
  };

  const handleSendEmail = (emails) => {
    setEmailLoader(true);

    const payload = {
      is_complete: false,
      step: -1,
      created_by: currentLogedInUser?.id,
      pt_id: participantId,
      sp_form_temp_id:
        checkboxes?.length > 0
          ? loopCheckbox(checkboxes)
          : selectedFormForEmail
          ? [
              {
                id: selectedFormForEmail.id,
                form_data: DATA_FORMATTER_FOR_DYNAMICS_FORMS(
                  selectedFormForEmail?.template
                ),
              },
            ]
          : [],
      public_access_emails: emails?.length > 0 ? emails : [],
      form_public_access: true,
      // form_data:DATA_FORMATTER_FOR_DYNAMICS_FORMS(selectedFormForEmail?.template)
    };

    apiPostRequest(URL_FORM_SEND_EMAIL_FOR_SP, payload)
      .then((res) => {
        if (res.status === 201) {
          setEmailLoader(false);
          setSelectedFormForEmail(null);
          setCheckboxes([]);
          setIsShowMultipleEmail(false);
          toast.success("E-from link sent successfully!");
        }
      })
      .catch((error) => {
        setEmailLoader(false);
        toast.error("E-from link not sent");
        setSelectedFormForEmail(null);
        console.log({ error });
      });
  };

  const loopCheckbox = (checkboxes) => {
    const data = checkboxes?.map((item) => ({
      id: item?.id,
      form_data: DATA_FORMATTER_FOR_DYNAMICS_FORMS(item?.template),
    }));
    return data;
  };

  console.log({checkboxes})

  return (
    <>
      <CustomButton
        label={buttonText}
        Icon={<BiPlus />}
        clickHandler={openModal}
      />
      <ModalSkeleton
        isOpen={isModalOpen}
        closeModal={() => closeModal()}
        cssClass='w-[60%]'
        title={
          slug === PARENT_FORM_CATEGORY_SLUG.RISK_MANAGEMENT.SLUG
            ? "Risk Evaluation"
            : buttonText
        }
      >
        <>
          <div className='flex justify-between items-center mt-3 mb-5'>
            <div className='flex  items-center gap-x-2 w-full'>
              {slug === PARENT_FORM_CATEGORY_SLUG.HIGH_CARE.SLUG && (
                <div className='w-2/5 md:w-4/12 lg:w-4/12'>
                  <SelectOption
                    isDisabled={fetchSubFormCatsLoader}
                    loading={fetchSubFormCatsLoader}
                    isSearch={false}
                    selectedOption={selectedSubCat}
                    handleChange={(e) => {
                      setSelectedSubCat(e);
                      handleFetch({ sub_cat_uuid: e.value, page: 1 });
                    }}
                    data={getSubCategoriesByParentSlug(slug)}
                    placeHolder='Filter Sub-Category'
                  />
                </div>
              )}
              {role !== "sil" && (
                <CustomButton
                  label='send selected forms via email'
                  Icon={<MdMarkEmailRead />}
                  clickHandler={() => setIsShowMultipleEmail(true)}
                  isDisabled={checkboxes?.length === 0}
                />
              )}
            </div>
            <Search setSearch={setSearch} search={search} />
          </div>
          <div className='shadow ring-1 ring-black ring-opacity-5 md:rounded-lg '>
            {request.isRequested ? (
              <div className='flex justify-center items-center custom-width-min'>
                <CircularLoader
                  classes='flex justify-center items-center'
                  classloader='loader-l'
                />
              </div>
            ) : (
              <table className='min-w-full divide-y divide-gray-300 mb-5 capitalize'>
                <thead className='bg-blue-50 text-sm font-semibold text-gray-900 sticky top-[-13px] capitalize'>
                  <tr>
                    {role !== "sil" && (
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6'
                      >
                        <CheckBox
                          checked={allSelected}
                          handleChange={(e) => {
                            if (supportPlanToMap?.length > 0) {
                              setAllSelected(e);
                              toggleCheckboxes(e);
                            }
                          }}
                        />
                      </th>
                    )}

                    {(slug ===
                      PARENT_FORM_CATEGORY_SLUG.PARTICIPANT_ASSESSMENT.SLUG ||
                      slug === PARENT_FORM_CATEGORY_SLUG.MEDICATION.SLUG ||
                      slug === PARENT_FORM_CATEGORY_SLUG.HIGH_CARE.SLUG) && (
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6'
                      >
                        Sr.
                      </th>
                    )}
                    <th
                      scope='col'
                      className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6'
                    >
                      Title
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold '
                    >
                      Category
                    </th>
                    {(slug === PARENT_FORM_CATEGORY_SLUG.RISK_MANAGEMENT.SLUG ||
                      slug === PARENT_FORM_CATEGORY_SLUG.HIGH_CARE.SLUG) && (
                      <th
                        scope='col'
                        className='px-3 py-3.5 text-left text-sm font-semibold '
                      >
                        Sub Category
                      </th>
                    )}
                    {/* {slug ===
                      PARENT_FORM_CATEGORY_SLUG.PARTICIPANT_ASSESSMENT.SLUG && (
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold "
                      >
                        Priority
                      </th>
                    )} */}
                    <ActionsForSubmitPtForms
                      slug={slug}
                      view={"table_header"}
                    />
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200 odd-bg-red-400 even-bg-white'>
                  {supportPlanToMap?.map((item, index) => {
                    return (
                      <tr
                        key={item.uuid}
                        className='even:bg-white odd:bg-gray-50'
                      >
                        {role !== "sil" && (
                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                            <div className='text-gray-500'>
                              <CheckBox
                                handleChange={(e) =>
                                  handleCheckboxChange(e, item)
                                }
                                checked={checkboxes?.indexOf(item) !== -1}
                              />
                            </div>
                          </td>
                        )}

                        {(slug ===
                          PARENT_FORM_CATEGORY_SLUG.PARTICIPANT_ASSESSMENT
                            .SLUG ||
                          slug === PARENT_FORM_CATEGORY_SLUG.MEDICATION.SLUG ||
                          slug ===
                            PARENT_FORM_CATEGORY_SLUG.HIGH_CARE.SLUG) && (
                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                            <div className='flex items-center'>
                              <div className=''>
                                <div className='capitalize font-medium text-gray-900'>
                                  {index + 1}
                                </div>
                              </div>
                            </div>
                          </td>
                        )}
                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='flex items-center'>
                            <div className=''>
                              <div className='capitalize font-medium text-gray-900'>
                                {item.title}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          <div className='text-gray-900'>
                            {item.category_name}
                          </div>
                        </td>
                        {(slug ===
                          PARENT_FORM_CATEGORY_SLUG.RISK_MANAGEMENT.SLUG ||
                          slug ===
                            PARENT_FORM_CATEGORY_SLUG.HIGH_CARE.SLUG) && (
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                            <div className='text-gray-900'>
                              {item.sub_category_name
                                ? item.sub_category_name
                                : "-"}
                            </div>
                          </td>
                        )}
                        {/* {slug ===
                          PARENT_FORM_CATEGORY_SLUG.PARTICIPANT_ASSESSMENT
                            .SLUG && (
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <div className="text-gray-900">
                              {item.is_mandatory ? "Mandatory" : "Optional"}
                            </div>
                          </td>

                        )} */}
                        <ActionsForSubmitPtForms
                          particpantUuid={particpantUuid}
                          item={item}
                          slug={slug}
                          view={"table_data"}
                          setIsShowMultipleEmail={setIsShowMultipleEmail}
                          role={role}
                          setSelectedFormForEmail={setSelectedFormForEmail}
                          checkboxes={checkboxes}
                          
                        />
                      </tr>
                    );
                  })}
                  {supportPlans?.data?.length === 0 ? (
                    <tr>
                      <td colSpan={7} className='py-6 text-center text-red-600'>
                        No data found !
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            )}
          </div>
          <div className='bg-white max-w-7xl mt-5 pb-10'>
            <PaginationComponent
              page={paginationObj.page}
              activeListLength={supportPlanToMap ? supportPlanToMap : []}
              limit={PARTICIPANT_LIST_LIMIT}
              totalList={paginationObj.total}
              handlePagination={(e) => {
                const data = _.cloneDeep(paginationObj);
                const obj = paginationObj;
                obj.page = e;
                setPaginationObj({ ...obj });
                setAllSelected(false)
                setCheckboxes([])
                if (e !== data.page) {
                  handleFetch({});
                }
              }}
              totalPages={paginationObj.totalPages}
            />
          </div>

          {isShowMultipleEmail && (
            <MultipleEmailModal
              // defaultData={supportPlanToMap?.public_access_users}
              showModal={isShowMultipleEmail}
              closeModalHandler={() => {
                setIsShowMultipleEmail(false);
              }}
              onClick={handleSendEmail}
              emailSendLoader={emailSendLoader}
            />
          )}
        </>
      </ModalSkeleton>
    </>
  );
};

export default CategoryFormsListing;
