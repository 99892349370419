import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { HiOutlineEye } from "react-icons/hi";
import {
  capitalizeFirstLetter,
  baseURLImage,
  getNameProfile,
} from "../../../../Utils/utils";
import CircularLoader from "../../../../components/common/CircularLoader/CircularLoader";
import Search from "../../../../components/common/Search/Search";
import TableHeader from "../../../../components/common/Table/TableHeader";
import {
  LOADER_STATUS,
  SORT_TYPE,
  StatusOptions,
} from "../../../../Constants/constant";
import SelectOption from "../../../../components/common/Select/Select";
import {
  HRM_BREADCRUMB_PAGES,
  HRM_BREADCRUMB,
} from "../../../../Constants/BreadcrumbConstant";
import Breadcrumb from "../../../../components/common/Breadcrumb";
import Header from "../../../../components/common/Header/Header";
import AddNewWorkerModal from "./AddNewWorker";
import { BiPlus } from "react-icons/bi";
import { connect } from "react-redux";
import { getSPApplicantsListRequest } from "../../../../store/actions/sp.actions";
import {
  getApplicants,
  getLoading,
} from "../../../../store/selector/sp.selector";
import { roleAccess } from "helpers/RolesPermission";
import { PERMISSION_KEYS, PERMISSION_KEYS_CHILDREN } from "store/constants";
import QuickView from "./WorkerQuickView";

const TeamManagementListTable = (props) => {
  const {
    templateList,
    isLoading,
    queryParams,
    handleFetch,
    getSortBy,
    renderLoading,
    isUserGracePeriodOver,
    setSelectedStatus,
    selectedStatus
  } = props;

  const [search, setSearch] = useState(null);
  const [sortBy, setSortBy] = useState(queryParams.sort_field);
  const [isOpenModel, setIsModelOpen] = useState(false);
  // const [selectedStatus, setSelectedStatus] = useState(null);
  const [sortDescending, setSortDescending] = useState(
    queryParams.sort_order === SORT_TYPE.DESC ? true : false
  );
  const [addWorkerRefresh, setAddWorker] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const handleImageError = (e, fname, lname) => {
    return (e.target.src = getNameProfile(fname, lname));
  };
  const checkStatus = (status) => {
    return status === "both" ? "both": status === "active"? true : false;
  };

  useEffect(() => getSortBy(sortBy), [sortBy]);
  //   useEffect(() => {
  //     if (addWorkerRefresh) {
  //         // Perform any actions needed to refresh the component
  //         // For example, you might call the function that fetches data or updates state.

  //         // Reset shouldRefresh back to false
  //         setAddWorker(false);
  //     }
  // }, [addWorkerRefresh]);

  useEffect(() => {
    setSortBy(queryParams.sort_field ? queryParams.sort_field : null);
    if (queryParams.sort_order) {
      setSortDescending(
        queryParams.sort_order
          ? queryParams.sort_order === SORT_TYPE.DESC
            ? true
            : false
          : false
      );
    }
    // if (queryParams.hasOwnProperty("filter")) {
    //   if (queryParams.filter !== null) {
    //     setSelectedStatus(
    //       queryParams.filter.status && queryParams.filter.status.length > 0
    //         ? StatusOptions.find(
    //             (x) => x.value === queryParams.filter.status[0]
    //           )
    //         : null
    //     );
    //   }
    // }

    if (queryParams.search) {
      setSearch(queryParams.search);
    }
  }, [queryParams]);



  

  useEffect(() => {
    // if (
    //   (queryParams.search === null && !search) ||
    //   queryParams.search === search
    // ) {
    //   return;
    // }

    if (search === null) return;

    const timeOutId = setTimeout(
      () =>
        handleFetch(
          {
            search: search,
          },
          sortBy
        ),

      1000
    );
    return () => clearTimeout(timeOutId);
  }, [search]);

  useEffect(
    () =>
      handleFetch(
        {
          search: search,
        },
        sortBy
      ),
    [addWorkerRefresh]
  );

  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(sortDescending);
    handleFetch(
      {
        sort_field: by,
        sort_order: !sortDescending ? SORT_TYPE.DESC : SORT_TYPE.ASC,
        status:selectedStatus?.value
      },
      sortBy
    );
  };

  return (
    <div>
      {isOpenModel && (
        <AddNewWorkerModal
          isUserGracePeriodOver={isUserGracePeriodOver}
          setAddWorker={setAddWorker}
          openModel={isOpenModel}
          handleClose={setIsModelOpen}
          addWorkerRefresh={addWorkerRefresh}
        />
      )}
      <Header
        title='Team Management'
        desc='All your workers (active & inactive) in one place'
        breadcrumb={
          <Breadcrumb
            pages={HRM_BREADCRUMB(HRM_BREADCRUMB_PAGES.TEAM_MANAGMENT)}
          />
        }
        {...(roleAccess(
          [],
          PERMISSION_KEYS.HRM,
          PERMISSION_KEYS_CHILDREN.HRM.WORKER.CREATE
        ) && {
          handleClick: () => setIsModelOpen(true),
          btnLabel: "Add Worker",
          icon: <BiPlus />,
          isButton: true,
        })}
      />

      <div className='max-w-7xl mx-auto'>
        <div className='flex sm:px-6 lg:px-8 justify-between items-center py-2 sticky top-[80px] bg-white z-[8]'>
          <div className='w-1/3 md:w-2/12 lg:w-2/12'>
            <SelectOption
              isDisabled={isLoading}
              isSearch={false}
              selectedOption={selectedStatus}
              handleChange={(e) => {
                setSelectedStatus(e);
                handleFetch({ status: e.value, page: 1 }, sortBy);
              }}
              data={StatusOptions}
              cssClass='z-20'
            />
          </div>
          <Search setSearch={setSearch} search={search} />
        </div>
        <div className='flex flex-col sm:px-6 lg:px-8'>
          <div className='overflow-hidden'>
            <div className='border md:rounded-lg'>
              <div className='block'>
                {isLoading ? (
                  <div
                    className={`flex flex-col w-full min-h-screen bg-gray-50 items-center justify-center`}
                  >
                    <CircularLoader
                      classes='flex justify-center items-center'
                      classloader='loader-l'
                    />
                  </div>
                ) : (
                  <table className='min-w-full divide-y divide-gray-300'>
                    <thead className='z-[7] bg-gray-50 sticky top-[0px] capitalize'>
                      <tr>
                        <TableHeader
                          sortName='first_name'
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          label={"Full Name with reference"}
                        />
                        <TableHeader
                          isSort={false}
                          //sortName='designation'
                          // flipSort={flipSort}
                          // sortBy={sortBy}
                          // sortDescending={sortDescending}
                          label={"Role"}
                        />
                        <TableHeader
                          isSort={false}
                          // sortName='email'
                          // flipSort={flipSort}
                          // sortBy={sortBy}
                          // sortDescending={sortDescending}
                          label={"Email Address"}
                        />

                        <TableHeader
                          isSort={false}
                          // sortName='status'
                          // flipSort={flipSort}
                          // sortBy={sortBy}
                          // sortDescending={sortDescending}
                          label={"Status"}
                        />

                        <TableHeader
                          isSort={false}
                          // sortName='phone'
                          // flipSort={flipSort}
                          // sortBy={sortBy}
                          // sortDescending={sortDescending}
                          label={"Phone Number"}
                        />
                        <TableHeader
                          isSort={false}
                          // sortName='action'
                          // flipSort={flipSort}
                          // sortBy={sortBy}
                          // sortDescending={sortDescending}
                          label={"Action"}
                        />
                      </tr>
                    </thead>
                    <tbody className='divide-y divide-gray-200 bg-white overflow-y-auto'>
                      {templateList !== null &&
                        templateList.length > 0 &&
                        templateList.map((participant, index) => (
                          <tr
                            key={index}
                            className='even:bg-white odd:bg-gray-50'
                          >
                            <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                              <div className='flex items-center'>
                                <div className='h-10 w-10 flex-shrink-0'>
                                  <img
                                    onError={(e) =>
                                      handleImageError(
                                        e,
                                        participant.first_name,
                                        participant.last_name
                                      )
                                    }
                                    className='capitalize object-cover h-10 w-10 rounded-full'
                                    src={
                                      participant.photo
                                        ? baseURLImage(participant.photo)
                                        : getNameProfile(
                                            participant.first_name,
                                            participant.last_name || " "
                                          )
                                    }
                                    alt=''
                                  />
                                </div>
                                <div className='ml-4 capitalize'>
                                  <Link
                                    className='capitalize font-medium text-gray-900 max-w-32'
                                    to={`/worker-profile/${participant.uuid}`}
                                    state={{ workerUUID: participant.uuid }}
                                  >
                                    <p className='w-40 truncate capitalize'>
                                      {`${participant.first_name} ${participant.last_name}`}
                                    </p>
                                  </Link>
                                </div>
                              </div>
                            </td>
                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                              <div className='text-gray-900'>
                                {capitalizeFirstLetter(participant.role)}
                              </div>
                            </td>
                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                              <div className='text-gray-900'>
                                {participant.email}
                              </div>
                            </td>

                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                              <span
                                className={`${
                                  participant.status
                                    ? "bg-green-100"
                                    : "bg-red-100"
                                } inline-flex rounded-full  px-2 text-xs font-semibold leading-5 text-black`}
                              >
                                {capitalizeFirstLetter(
                                  participant.status ? "active" : "Inactive"
                                )}
                              </span>
                            </td>

                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                              {participant.phone}
                            </td>
                            <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium'>
                              <div className='flex items-center justify-start gap-x-3'>
                                {/* <Link
                                    to={`/sp-profile/${participant.uuid}`}
                                    className="icon-pencil-wrapper"
                                    title="Edit"
                                  >
                                    <BiPencil className="icon-size" />
                                    <span className="sr-only">
                                      {participant.uuid}
                                    </span>
                                  </Link> */}

                                <div
                                  onClick={() => {
                                    setIsOpen(true);
                                    setSelectedParticipant(participant);
                                  }}
                                  tabIndex={0}
                                  role='button'
                                  className='icon-eye-wrapper'
                                  title='View'
                                >
                                  <HiOutlineEye className='icon-size' />
                                  <span className='sr-only'>
                                    {/* {participant.uuid} */}
                                  </span>
                                </div>
                              </div>
                            </td>
                            {/* <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                              <Link
                                className='icon-eye-wrapper inline-block'
                                to={`/worker-profile/${participant.uuid}`}
                                state={{ workerUUID: participant.uuid }}
                              >
                                <HiOutlineEye className='icon-size' />
                              </Link>
                            </td> */}
                          </tr>
                        ))}
                      {templateList.length === 0 &&
                        renderLoading === LOADER_STATUS.COMPLATE &&
                        templateList?.length === 0 && (
                          <tr>
                            <td
                              colSpan={7}
                              className='py-6 text-center text-red-600'
                            >
                              No data found !
                            </td>
                          </tr>
                        )}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* side over Modal */}
      {isOpen && (
        <QuickView
          paddingLeft='px-0'
          selectedParticipant={selectedParticipant}
          isOpen={isOpen}
          setIsOpen={() => {
            setIsOpen(false);
            setSelectedParticipant({});
          }}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = {
  getApplicantsList: getSPApplicantsListRequest,
};

const mapStateToProps = (state) => {
  const spApplicants = getApplicants(state);
  const loading = getLoading(state);
  return { loading, spApplicants };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamManagementListTable);
