import { connect } from "react-redux";
import TimeSheet from "./TimeSheet";
import {
  workerTimeSheetsFetchRequest,
  participantListForTimeSheetRequest,
  setSelectedTimeSheetLocally,
  timeSheetDataUpdateRequest,
  timeSheetStatusChangeRequest,
  workerTimeSheetsLocallySearch,
  resetWorkerTimeSheets,
} from "store/actions/rostering.actions";
import {
  getIsAnyPendingExist,
  getIsNextSelectedTimeSheetOfParticipant,
  getParticipantListForTimesheet,
  getParticipantListForTimesheetLoader,
  getSelectedTimesheetOfParticipant,
  getTimesheetStatusChangeLoader,
  getTimesheetupdateLoader,
  getWorkerTimeSheetsList,
  getWorkerTimesheetListPagination,
  getWorkerTimesheetLoader,
  getWorkerTimesheetPaginationLoader,
} from "store/selector/rostering.selector";
import {
  getSpRosteringSetting,
  getSpRosteringSettingLoader,
  getSpSetting,
} from "store/selector/sp.selector";
import { getServiceCatalogStates } from "store/selector/cos.selector";
import { getCatalogServicesSubCategoryRequest } from "store/actions/cos.actions";
import { spRosteringSettingsRequest } from "store/actions/sp.actions";

const mapDispatchToProps = {
  workerTimeSheetsFetchRequest,
  participantListForTimeSheetRequest,
  setSelectedTimeSheetLocally,
  timeSheetDataUpdateRequest,
  timeSheetStatusChangeRequest,
  workerTimeSheetsLocallySearch,
  getCatalogServicesSubCategoryRequest,
  resetWorkerTimeSheets,
  spRosteringSettingsRequest,
};

const mapStateToProps = (state) => {
  const participantListForTimesheetLoader =
    getParticipantListForTimesheetLoader(state);
  const participantListForTimesheet = getParticipantListForTimesheet(state);
  const workerTimesheetLoader = getWorkerTimesheetLoader(state);
  const workerTimeSheets = getWorkerTimeSheetsList(state);
  const selectedTimeSheet = getSelectedTimesheetOfParticipant(state);
  const currentSpUser = getSpSetting(state);
  const updateTimesheetLoader = getTimesheetupdateLoader(state);
  const timesheetStatusChangeLoader = getTimesheetStatusChangeLoader(state);
  const isAnyPendingExist = getIsAnyPendingExist(state);
  const catalogStates = getServiceCatalogStates(state);
  const workerTimesheetPagination = getWorkerTimesheetListPagination(state);
  const workerTimesheetPaginationLoader =
    getWorkerTimesheetPaginationLoader(state);
  const spRosteringSettingLoader = getSpRosteringSettingLoader(state);
  const spRosteringSetting = getSpRosteringSetting(state);

  const isNextTimeSheet = getIsNextSelectedTimeSheetOfParticipant(state);

  return {
    participantListForTimesheetLoader,
    participantListForTimesheet,
    workerTimesheetLoader,
    workerTimeSheets,
    selectedTimeSheet,
    currentSpUser,
    updateTimesheetLoader,
    timesheetStatusChangeLoader,
    isAnyPendingExist,
    catalogStates,
    workerTimesheetPagination,
    workerTimesheetPaginationLoader,
    spRosteringSettingLoader,
    spRosteringSetting,
    isNextTimeSheet,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeSheet);
