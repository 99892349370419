import React, { useContext, useState } from "react";
import CustomButton from "components/common/Button/CustomButton";
import MultiFileInput from "components/common/FileUpload/MultiFileInput";
import { LOADER_STATUS } from "store/constants";
import api from "store/services/sil.services";
import { uploadFilesOnVapour } from "Utils/uploadFileOnS3";
import { toast } from "react-toastify";
import _ from "lodash";
import ModalSkeleton from "components/Modals/ModalSkeleton";
import { LoginContext } from "helpers/LoginContext";

const RoomDocsUploader = ({ room, handleUpdateRoomDetails, type }) => {
  const { isUserGracePeriodOver } = useContext(LoginContext);
  const [isUploadLoading, setIsUploadLoading] = useState(LOADER_STATUS.NA);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [files, setFiles] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isDeleteFileLoader, setisDeleteFileLoader] = useState(null);

  const handleUploadAttachments = async () => {
    try {
      setIsUploadLoading(LOADER_STATUS.PENDING);
      const filteredFiles = files?.filter((x) => !x.hasOwnProperty("id"));
      const filesLength = filteredFiles.length;
      const uploadedFiles = [];
      const progressTracker = new Array(filesLength).fill(0);

      let lastUpdate = Date.now();

      const onProgress = (progress, fileIndex) => {
        const currentTime = Date.now();
        if (currentTime - lastUpdate < 100) return;
        lastUpdate = currentTime;
        progressTracker[fileIndex] = progress;
        const totalProgress = progressTracker.reduce(
          (acc, curr) => acc + curr,
          0
        );
        const averageProgress = totalProgress / filesLength;
        setUploadProgress(averageProgress);
      };

      const uploadFileWithProgress = async (file, index) => {
        const options = {
          progress: (progress) => onProgress(progress, index),
        };
        const uploadedFile = await uploadFilesOnVapour([file], options);
        if (uploadedFile && uploadedFile?.[0]) {
          uploadedFiles.push(uploadedFile[0]);
        }
      };
      await Promise.all(
        filteredFiles.map((file, index) => uploadFileWithProgress(file, index))
      );

      const response = await api.silRoomAttachmentUploadApi({
        attachments: uploadedFiles,
        room_uuid: room.uuid,
        type,
      });
      setUploadProgress(0);
      setIsUploadLoading(LOADER_STATUS.COMPLETE);
      toast.success("Attachment uploaded successfully!");
      handleUpdateRoomDetails({ ...room, ...response });
      handleClose();
    } catch (err) {
      console.error("Error during upload:", err);
      setUploadProgress(0);
      setIsUploadLoading(LOADER_STATUS.COMPLETE);
    }
  };

  const handleClose = () => {
    setIsOpenModal(false);
    setFiles([]);
  };

  const handleDelete = async (item) => {
    try {
      setisDeleteFileLoader(item.id);
      const response = await api.silRoomAttachmentDeleteApi({ id: item.id });
      if (response) {
        toast.success("Attachment deleted successfully!");
        const prevData = _.cloneDeep(room?.attachments);
        const index = room?.attachments?.findIndex((x) => x.id === item.id);
        if (index !== -1) {
          prevData.splice(index, 1);
        }
        handleUpdateRoomDetails({ ...room, attachments: prevData });
      }
      setisDeleteFileLoader(null);
    } catch (err) {
      setisDeleteFileLoader(null);
      console.log("err", err);
    }
  };

  const modalFooter = () => (
    <>
      <CustomButton
        isDisabled={isUploadLoading === LOADER_STATUS.PENDING}
        label='Cancel'
        variant='secondary'
        clickHandler={handleClose}
      />
      <CustomButton
        showLoading={isUploadLoading === LOADER_STATUS.PENDING}
        isDisabled={isUploadLoading === LOADER_STATUS.PENDING}
        label={"Upload"}
        variant='primary'
        clickHandler={() => {
          if (isUserGracePeriodOver) return;
          if (files && files?.length > 0) {
            handleUploadAttachments();
          } else {
            toast("Atleast upload one file", {
              toastId: "room-upoad-image-limit",
            });
          }
        }}
      />
    </>
  );

  return (
      <div className='rounded-md rounded-lg border border-gray-300 shadow-sm p-2 px-4'>
        <div className='col-span-2'>
          <label className='text-gray-700 text-base font-semibold mb-1'>
            {type === "incoming" ? "Room Incoming Attachments" : "Condition at Lease End"}
          </label>
        </div>
        <MultiFileInput
            callFrom='Silhome-Property-profile'
            onlyShow={false}
            files={room?.attachments?.filter((x) => x.hasOwnProperty("id"))}
            isClickUploaderButton={(val) => {
              if (val) {
                setIsOpenModal(true);
              }
            }}
            isDeleteFileLoader={isDeleteFileLoader}
            handleDelete={(item) => handleDelete(item)}
            noFileMsg='No Room Image Found!'
        />
        <div className=''>
          <ModalSkeleton
              title={`Upload Room Images`}
              cssClass='w-[40%]'
              modalFooter={modalFooter()}
              isOpen={isOpenModal}
              closeModal={handleClose}
          >
            <div className='flex flex-col'>
              <div className='w-full'>
                <div>
                  <MultiFileInput
                      filenameLimit={150}
                      size={5}
                      maxFilesAllow={20}
                      type='image'
                      handleFile={(e) => {
                        setFiles(e);
                      }}
                      files={files}
                      showDragAndDrop={true}
                      handleDelete={(e) => setFiles([...e])}
                      // isHardClearAllStates={isSubmitting === true}
                      // setFilesData={setFilesData}
                      // setIsSubmitting={setIsSubmitting}
                  />
                </div>

                {isUploadLoading === LOADER_STATUS.NA &&
                files?.filter((x) => !x.hasOwnProperty("id"))?.length > 0 ? (
                    <div className='mt-2 rounded-md p-2 bg-blue-100 flex justify-center items-center'>
                      {`${files?.filter((x) => !x.hasOwnProperty("id"))?.length} ${
                          files?.filter((x) => !x.hasOwnProperty("id"))?.length > 1
                              ? "files"
                              : "file"
                      } loaded.`}
                    </div>
                ) : isUploadLoading === LOADER_STATUS.PENDING ? (
                    <div className='w-full bg-gray-200 rounded-full dark:bg-gray-700 mt-2'>
                      <div
                          className='animate-pulse transition duration-300 ease-in-out bg-purple-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full'
                          style={{
                            width: `${uploadProgress}%`,
                          }}
                      >
                        {Math.round(uploadProgress)}%
                      </div>
                    </div>
                ) : (
                    <></>
                )}
                {/* (
            isUploadLoading === LOADER_STATUS.COMPLETE && (
              <div className='mt-2 rounded-md p-2 bg-blue-100 flex justify-center items-center'>
                Attachment Uploaded!
              </div>
            )
          )} */}
              </div>
            </div>
          </ModalSkeleton>
        </div>
      </div>
  );
};

export default RoomDocsUploader;
