import React from "react";
import appointment from "../../../../assets/dashboard/appointment.jpg";
import {
  baseURLImage,
  getNameProfile,
  formatDateToAustralian,
} from "Utils/utils";

const AppointmentCard = ({ item }) => {
  const backgroundImageStyle = {
    backgroundImage: `url(${appointment})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  const isToday = (date) => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() && date.getMonth() === today.getMonth()
    );
  };

  const appointmentDate = new Date(item?.date);
  const UpcomingAppointment = isToday(appointmentDate);

  const handleImageError = (e, fname, lname) => {
    return (e.target.src = getNameProfile(fname, lname));
  };

  return (
    <div className={`h-full w-full p-6 border`} style={backgroundImageStyle}>
      <div className='flex items-center'>
        <div className='h-12 w-12 flex-shrink-0 mr-4'>
          <img
            onError={(e) => handleImageError(e, item?.participant_name, item?.participant_name)}
            className='object-cover h-12 w-12 rounded-full'
            src={
              item?.photo
                ? baseURLImage(item?.photo)
                : getNameProfile(item.participant_name, item?.participant_name || " ")
            }
            alt=''
          />
        </div>
        <div className='text-left'>
          <div className='font-lg font-bold text-gray-900 capitalize'>
            {item?.participant_name} {/* Displaying the Title */}
          </div>
          <p className={`text-xs   } `}>
            Has Appointment{" "}
            {UpcomingAppointment ? (
              <strong>Today</strong>
            ) : (
              <>
                on <strong>{formatDateToAustralian(item?.date)}</strong>
              </>
            )}{" "}
            at <strong>{item?.time}</strong>
          </p>
          <p className={`text-sm text-bold`}>{item?.title} </p>

          {item?.assignedWorkers && item.assignedWorkers.length > 0 && (
            <p className='text-sm text-gray-700'>
              Assigned Worker:{" "}
              {item.assignedWorkers.map((worker) => worker.label).join(", ")}{" "}
            </p>
          )}
          <p className={`text-xs   } `}>{item?.location}</p>
        </div>
      </div>
    </div>
  );
};

export default AppointmentCard;
