import React, { useMemo, useState } from "react";
import ModalSkeleton from "../../../Modals/ModalSkeleton";
import CustomButton from "components/common/Button/CustomButton";
import moment from "moment";
import { useForm } from "react-hook-form";
import SelectOption from "components/common/Select/Select";
import SwitchInput from "components/common/Input/SwitchInput";

const ViewAppointmentModal = (props) => {
  const {
    handleClose,
    open,
    setOpen,
    appointment,
    isUserGracePeriodOver,
    participantAppointmentDeleteLocally,
    appointmentId,
  } = props;
  const [request, setRequest] = useState({
    isRequested: false,
    isSuccess: false,
    isFailed: false,
  });

  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
    watch,
  } = useForm();

  const workers = useMemo(() => {
    const preparedData = appointment?.assigned_workers?.map((worker) => ({
      label: `${worker?.first_name} ${worker?.last_name}`,
      value: worker?.id,
    }));
    return preparedData;
  }, [appointment?.assigned_workers]);
  const renderStatus = () => {
    if (!appointment || !appointment.status) {
      return (
        <div className='mt-4'>
          <h3 className='text-sm font-medium text-gray-700'>Status: Unknown</h3>
          <p className='text-sm text-gray-500'>
            No status available for this appointment.
          </p>
        </div>
      );
    }

    const statusDetails = {
      Rescheduled: {
        description:
          "The original appointment has been moved to a new date or time.",
        useCase: "Tracks modifications to the original schedule post-creation.",
      },
      Completed: {
        description: "The appointment has been successfully concluded.",
        useCase:
          "Indicates the appointment has finished and no further actions are required.",
      },
      Canceled: {
        description: "The appointment has been canceled and will not occur.",
        useCase:
          "Used when an appointment is canceled by either party or due to unforeseen circumstances.",
      },
    };

    const status = appointment.status;

    return (
      <div className='mt-4'>
        <h3 className='text-sm font-medium text-gray-700'>
          Status: {status.charAt(0).toUpperCase() + status.slice(1)}
        </h3>
        <p className='text-sm text-gray-500'>
          {statusDetails[status]?.description || "No description available."}
        </p>
        <p className='text-sm text-gray-500 italic'>
          {statusDetails[status]?.useCase || "No use case available."}
        </p>
      </div>
    );
  };

  const modalFooter = () => (
    <>
      {/* <CustomButton
        showLoading={request.isRequested}
        isDisabled={request.isRequested || isUserGracePeriodOver}
        label='Yes, delete this Appointment'
        // clickHandler={handleDelete}
        variant='danger'
      /> */}
      <CustomButton label='Close' onClick={handleClose} variant='danger' />
    </>
  );
  console.log({ appointment });

  return (
    <ModalSkeleton
      modalFooter={modalFooter()}
      isOpen={open}
      closeModal={() => setOpen(false)}
      title='View Appointment'
      cssClass='w-[50%]'
    >
      <div className='flex flex-col'>
        <p className='text-sm text-gray-500 mb-2'>
          Here are the details of the appointment:
        </p>
        <form className='space-y-3'>
          <div>
            <label
              htmlFor='title'
              className='block text-sm font-medium text-gray-700'
            >
              Title
            </label>
            <div className='mt-1'>
              <input
                value={appointment.title}
                readOnly
                className='did-floating-input'
                type='text'
              />
            </div>
          </div>
          <div>
            <label
              htmlFor='date'
              className='block text-sm font-medium text-gray-700'
            >
              Date
            </label>
            <div className='mt-1'>
              <input
                value={moment(appointment.date, "DD MMMM, YYYY").format(
                  "YYYY-MM-DD"
                )}
                readOnly
                type='date'
                name='date'
                id='date'
                min={moment().format("YYYY-MM-DD")}
                className='focus:bg-[#4b6bb710] px-4 py-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
              />
            </div>
          </div>
          <div>
            <label
              htmlFor='time'
              className='block text-sm font-medium text-gray-700'
            >
              Time
            </label>
            <div className='mt-1'>
              <input
                value={appointment.time}
                readonly
                type='time'
                name='time'
                id='time'
                min={moment().format("HH:MM")}
                className='focus:bg-[#4b6bb710] px-4 py-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
              />
            </div>
          </div>
          <div>
            <label
              htmlFor='address'
              className='block text-sm font-medium text-gray-700'
            >
              Address
            </label>
            <div className='mt-1'>
              <input
                value={appointment.location}
                readonly
                type='text'
                name='location'
                id='location'
                className='focus:bg-[#4b6bb710] px-4 py-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
              />
            </div>
          </div>
          <div>
            <label
              htmlFor='notes'
              className='block text-sm font-medium text-gray-700'
            >
              Appointment Notes
            </label>
            <div className='mt-1'>
              <textarea
                // {...register("notes", {
                //   required: true,
                // })}
                value={appointment.notes}
                readOnly
                rows={3}
                name='notes'
                id='notes'
                className='focus:bg-[#4b6bb710] overflow-y-auto border px-4 py-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
              />
              {errors?.notes?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required!
                </span>
              )}
            </div>
          </div>
          <div>
            <label
              htmlFor='notes'
              className='block text-sm font-medium text-gray-700'
            >
              Assigned Workers
            </label>
            <div className='mt-1'>
              <SelectOption
                isMulti={true}
                isDisabled={true}
                // data={workers}
                selectedOption={workers}
                // handleChange={setAssignedWorkers}
              />
            </div>
          </div>
          {appointment.comment && (
            <div>
              <label
                htmlFor='comment'
                className='block text-sm font-medium text-gray-700'
              >
                Comments
              </label>
              <div className='mt-1'>
                <textarea
                  value={appointment.comment}
                  readOnly
                  rows={3}
                  name='comment'
                  id='comment'
                  className='focus:bg-[#4b6bb710] overflow-y-auto border px-4 py-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
                />
              </div>
            </div>
          )}
        </form>
        {renderStatus()}
      </div>
    </ModalSkeleton>
  );
};

export default ViewAppointmentModal;
