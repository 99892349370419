/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UseRoute } from "./config/UseRoutes";
import { LoginContext } from "./helpers/LoginContext";
import { apiPostRequest } from "./helpers/Requests";
import {
  getRefreshToken,
  // getToken,
  // setExpireTime,
  setToken,
} from "./helpers/Tokens";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, Slide, toast } from "react-toastify";
// import CpModal from "./components/Modals/CPModal/CpModal";
import ToastMessage from "./components/common/Toaster/ToastMessage";
import pusher from "components/common/NotificationDropDown/Pusher";
import { getSpSetting } from "store/selector/sp.selector";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "store/selector/auth.selector";
import { NOTIFICATION_IDS } from "store/constants";
import {
  roleAuthenticateUserRequest,
  payrollAuthenticationUpdateLocally,
  payrollExportCallback,
  authenticatedUser,
} from "store/actions/auth.actions";
import { showToast } from "store/actions/general.actions";
import { TOAST_TYPE, USER_ROLE } from "Constants/constant";
import { getWorkerTimeSheetExportList } from "store/selector/rostering.selector";
import { roleAccess } from "helpers/RolesPermission";
import GetItemFromLocalstorage from "Utils/GetItemFromLocalstorage";
import { currentLoggedInIdAndUUID, parseBooleanVal } from "Utils/utils";
import VertexBanner from "components/common/Banner/Banner";
import api from "store/services/sp.services";
import useDefaultPaymentMethod from "hooks/useDefaultPaymentMethod";
import TrialExpireModal from "components/Modals/TrialExpiredModal/TrialExpireModal";
import GracePeriodModal from "components/Modals/GracePeriodModal/GracePeriodModal";
import useGracePeriodOverHook from "hooks/useGracePeriodHook";
import { participantUpdatePusherReceived } from "store/actions/participant.actions";
import { animateScroll as scroll } from "react-scroll";

const App = () => {
  const navigate = useNavigate();

  const isUserGracePeriodOver = useGracePeriodOverHook();
  // const isUserGracePeriodOver = true;

  var refreshToken;
  const [sideBarPadding, setSideBarPadding] = useState(false);
  const currentSpUser = useSelector(getSpSetting);
  const currentLoggedInUser = useSelector(getUser);

  const workerExportListSelector = useSelector(getWorkerTimeSheetExportList);

  const {} = useDefaultPaymentMethod();

  const dispatch = useDispatch();

  const [refreshNavigations, setRefreshNavigations] = useState(0);

  const refreshPermissionsHandler = () => {
    setRefreshNavigations((prevState) => !prevState);
  };

  useEffect(() => {
    window.addEventListener("refreshPermissions", refreshPermissionsHandler);
    return () => {
      window.removeEventListener(
        "refreshPermissions",
        refreshPermissionsHandler
      );
    };
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//js-na1.hs-scripts.com/45408478.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const clearSession = () => {
    clearTimeout(refreshToken);
  };

  // const [warnModel,setWarnModel]=useState(true)
  // const [notify, setNotify] = useState(true);

  // const myFunction = () => {
  //   const current = moment().format("HH:mm");
  //   // const current = moment();
  //   const { logInTime, warnTime, logoutTime } = getExpireTime();

  //   if (current === logoutTime) {
  //     dispatch(callLogout());
  //   } else if (current === warnTime && notify) {
  //     setNotify(false);
  //     dispatch(
  //       showMessageBoxForTimeExpiry({
  //         title: "SessionExpire",
  //         message: "5 min left in session expiry. Do You Want to login again",
  //         type: true,
  //       })
  //     );
  //   } else if (notify) {
  //     setNotify(false);
  //   }
  // };
  // setInterval(myFunction, 120000);

  const refreshTokenTimeout = (expirationTime) => {
    refreshToken = setTimeout(() => {
      refreshTokenRequest();
    }, expirationTime * 1000);
  };

  const refreshTokenRequest = async () => {
    const token = getRefreshToken();
    const data = {
      token,
    };
    if (!token) {
      return;
    }
    try {
      let res = await apiPostRequest("refresh-token", data);
      if (res.status !== 200) {
        console.log("Throw Some Errors");
      }
      if (res.status === 200) {
        setToken(res.data.data.AccessToken);
        const expirationDate = new Date(new Date().getTime() + 3600 * 1000);
        const refreshTokenDate = new Date(new Date().getTime() + 3600 * 1000);
        localStorage.setItem("expirationDate", expirationDate);
        localStorage.setItem("refreshTokenDate", refreshTokenDate);
        navigate("/dashboard");
      }
    } catch (err) {
      console.log(err);
    }
  };

  // const handleExtend = () => {
  //   const logInTime = moment().format("HH:mm");
  //   const warnTime = moment().add(120000, "milliseconds").format("HH:mm");
  //   const logoutTime = moment().add(7200000, "milliseconds").format("HH:mm");
  //   setExpireTime({ logInTime, warnTime, logoutTime });
  //   setIsWarnModel(false);
  // };

  // const [isWarnModel, setIsWarnModel] = useState();

  // const handleClose = () => {
  //   setIsWarnModel(false);
  // };

  // useEffect(() => {
  //   if (isRefreshPusher) {
  //     pusherUpdate();
  //   }
  // }, [isRefreshPusher]);

  const unSubscribeChannels = () => {
    if (pusher) {
      const userObjForSp = currentLoggedInIdAndUUID(true);
      const userObjForNonSp = currentLoggedInIdAndUUID(false);

      if (userObjForSp) {
        pusher.unsubscribe(`payroll-${userObjForSp.uuid}`);
      }
      const sp_id =
        currentLoggedInUser?.sp_id || currentLoggedInUser?.service_provider?.id;

      if (sp_id) {
        pusher.unsubscribe(`role-sp-${sp_id}`);
        pusher.unsubscribe(`stripe-${sp_id}`);
      }

      // if (currentSpUser) pusher.unsubscribe(`payroll-${currentSpUser.uuid}`);
      // if (currentLoggedInUser) {
      //   pusher.unsubscribe(`role-sp-${currentLoggedInUser.sp_id}`);
      // }
    }
  };

  useEffect(() => {
    pusherUpdate();

    return () => {
      if (pusher) {
        const userObjForSp = currentLoggedInIdAndUUID(true);
        // const userObjForNonSp = currentLoggedInIdAndUUID(false);
        if (userObjForSp) {
          pusher.unsubscribe(`payroll-${userObjForSp.uuid}`);
        }
        const sp_id =
          currentLoggedInUser?.sp_id ||
          currentLoggedInUser?.service_provider?.id;

        if (sp_id) {
          pusher.unsubscribe(`role-sp-${sp_id}`);
          pusher.unsubscribe(`stripe-${sp_id}`);
          pusher.unsubscribe(`participant-sp-${sp_id}`);
        }

        // if (currentSpUser) pusher.unsubscribe(`payroll-${currentSpUser.uuid}`);
        // if (currentLoggedInUser) {
        //   pusher.unsubscribe(`role-sp-${currentLoggedInUser.sp_id}`);
        // }
      }
    };
  }, []);

  useEffect(() => pusherUpdate(), [currentSpUser, currentLoggedInUser]);

  const payrollToster = (obj) => {
    if (
      obj.message &&
      obj.message.key === NOTIFICATION_IDS.PAYROLL_TIMESHEET_CALLBACK
    ) {
      if (
        workerExportListSelector &&
        workerExportListSelector.length > 0 &&
        obj.message &&
        obj.message?.worker_uuid
      ) {
        const index = workerExportListSelector.findIndex(
          (x) => x.uuid === obj.message?.worker_uuid
        );
        if (index !== -1) {
          dispatch(
            showToast({
              type:
                obj.message?.status === true
                  ? TOAST_TYPE.SUCCESS
                  : TOAST_TYPE.ERROR,
              title: obj.message?.message || "",
            })
          );
        }
      }
    }
  };

  const pusherUpdate = async () => {
    if (!currentLoggedInUser || !currentLoggedInUser.uuid) {
      return;
    }
    const user = GetItemFromLocalstorage("user");
    if (user === null) {
      return;
    }

    const userObjForSp = currentLoggedInIdAndUUID(false);
    const userObjForNonSp = currentLoggedInIdAndUUID(false);

    const sp_id =
      currentLoggedInUser?.sp_id || currentLoggedInUser?.service_provider?.id;

    //need to discuss
    const subscribedChannels = Object.keys(pusher?.channels?.channels);

    if (userObjForSp) {
      const payrollChannelName = `payroll-${userObjForSp.uuid}`;
      if (!subscribedChannels.includes(payrollChannelName)) {
        const payrollChannel = pusher.subscribe(payrollChannelName);

        payrollChannel.bind("notify", (obj) => {
          console.log("Payroll Pusher received");
          if (
            obj.message &&
            obj.message.key === NOTIFICATION_IDS.PAYROLL_AUTHENTICATION
          ) {
            dispatch(
              payrollAuthenticationUpdateLocally({
                ...obj.message,
              })
            );
          }
          if (
            obj.message &&
            obj.message.key === NOTIFICATION_IDS.PAYROLL_TIMESHEET_CALLBACK
          ) {
            payrollToster({ ...obj });
            dispatch(payrollExportCallback({ ...obj.message }));
          }
        });
      }

      // Participant Update
      const spParticipantChannelName = `participant-sp-${sp_id}`;
      if (!subscribedChannels.includes(spParticipantChannelName)) {
        const spParticipantChannel = pusher.subscribe(spParticipantChannelName);
        spParticipantChannel.bind("notify", async (obj) => {
          if (
            obj.message &&
            obj.message.key === NOTIFICATION_IDS.PARTICIPANT_UPDATE
          ) {
            dispatch(participantUpdatePusherReceived(obj.message.data));
          }
        });
      }

      if (roleAccess([USER_ROLE.Sp_Admin])) {
        //Stripe
        const StripeChannelName = `stripe-${sp_id}`;
        if (!subscribedChannels.includes(StripeChannelName)) {
          const stripeChannel = pusher.subscribe(StripeChannelName);
          stripeChannel.bind("notify", async (obj) => {
            if (
              obj.message &&
              obj.message.key === NOTIFICATION_IDS.STRIPE_SUBSCRIPTION
            ) {
              // const paymentSetting = JSON.parse(obj.message.payment_settings);

              if (obj.message.status === "failed") {
                const response = await api.stirpeGetDefaultPaymentMethod();
                if (response?.payment_setting) {
                  const user = await GetItemFromLocalstorage("user");
                  if (user) {
                    user.payment_setting = response?.payment_setting;
                    localStorage.setItem("user", JSON.stringify(user));
                    dispatch(authenticatedUser(user));
                  }
                }
                toast.error(
                  response?.payment_setting?.errorMessage || "Payment Failed!"
                );
              } else if (obj.message.status === "success") {
                toast.success(obj.message.message);
                // const user = await GetItemFromLocalstorage("user");
                // if (user) {
                //   user.payment_setting = obj.message.payment_settings;
                //   localStorage.setItem("user", JSON.stringify(user));
                //   dispatch(authenticatedUser(user));
                // }
                const response = await api.stirpeGetDefaultPaymentMethod();
                const user = await GetItemFromLocalstorage("user");
                if (user) {
                  user.payment_setting = response?.payment_setting;
                  localStorage.setItem("user", JSON.stringify(user));
                  dispatch(authenticatedUser(user));
                }
              }
            }
          });
        }
      }
    }

    if (userObjForNonSp && !parseBooleanVal(roleAccess([USER_ROLE.Sp_Admin]))) {
      const roleChannelName = `role-sp-${sp_id}`;
      // const roleChannelName = `role-sp-${userObjForNonSp.id}`;
      if (!subscribedChannels.includes(roleChannelName)) {
        const roleChannel = pusher.subscribe(roleChannelName);
        roleChannel.bind("notify", (obj) => {
          if (obj.message && obj.message.key === NOTIFICATION_IDS.ROLE_UPDATE) {
            console.log("Update role!");
            dispatch(roleAuthenticateUserRequest());
          }
        });
      }
    }
  };

  const [isDisplayBanner, setIsDisplayBanner] = useState(false);
  const [isTrialChoosePlanBtnClick, setIsTrailChoosePlanBtnClick] = useState(0);

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      {currentLoggedInUser &&
        "id" in currentLoggedInUser &&
        roleAccess([USER_ROLE.Sp_Admin]) &&
        parseBooleanVal(currentLoggedInUser?.payment_setting?.wildcard) ===
          false &&
        isDisplayBanner && (
          <VertexBanner
            setIsTrailChoosePlanBtnClick={setIsTrailChoosePlanBtnClick}
          />
        )}
      <LoginContext.Provider
        value={{
          refreshTokenTimeout,
          clearSession,
          setSideBarPadding,
          sideBarPadding,
          refreshNavigations,
          isUserGracePeriodOver,
          unSubscribeChannels,
          isTrialChoosePlanBtnClick,
          scrollToTop,
        }}
      >
        <UseRoute setIsDisplayBanner={setIsDisplayBanner} />
      </LoginContext.Provider>
      {/* <Confirmation /> */}
      {/* <CpModalExpiryLougOut /> */}
      <ToastMessage />
      <ToastContainer
        position='top-right'
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Slide}
      />
      {isDisplayBanner && roleAccess([USER_ROLE.Sp_Admin]) && (
        <>
          <TrialExpireModal />
          <GracePeriodModal />
        </>
      )}
    </>
  );
};
export default App;
