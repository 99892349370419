import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { LoginContext } from "../../../../helpers/LoginContext";
import { baseURLImage, getNameProfile } from "../../../../Utils/utils";
import CircularLoader from "../../../../components/common/CircularLoader/CircularLoader";
import Header from "../../../../components/common/Header/Header";
import SideBar from "../../../../components/common/SideBar/SideBarNew";
import CustomButton from "../../../../components/common/Button/CustomButton";
// import StaffCompliance from "../StaffCompliance/index";
import Breadcrumb from "components/common/Breadcrumb";
import {
  SETTINGS_BREADCRUMB_PAGES,
  SP_SETTING_BREADCRUMB,
} from "Constants/BreadcrumbConstant";
import { REGEX_PHONE_NUMBER } from "Constants/constant";
import InputField from "components/common/Input/Input";

const SpSetting = (props) => {
  const {
    getSpSettingRequest,
    isLoading,
    settings,
    settingUpdateLoading,
    spSettingUpdateRequest,
  } = props;
console.log({settings})
  const { sideBarPadding, isUserGracePeriodOver } = useContext(LoginContext);

  const [profilePicture, setProfilePicture] = useState(null);
  const [settingData, setSettingData] = useState(null);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    if (settings) {
      setSettingData(settings);
      reset({ ...settings });
    }
  }, [settings]);


  useEffect(()=>{
setValue("branding_name",settings?.slug);
setValue("branding_details",settings?.branding_text)
  },[settings?.slug,settings?.branding_text])
  const onSubmit = async (data) => {

    if (isUserGracePeriodOver) {
      return;
    }
    let formData = new FormData();

    if (profilePicture) {
      formData.append("profile_picture", profilePicture ? profilePicture : "");
    }
    formData.append("business_name", data.name ? data.name : "");
    formData.append("email", data.email ? data.email : "");
    formData.append("new_email", data.new_email ? data.new_email : "");
    formData.append("abn", data.abn ? data.abn : "");
    formData.append("asn", data.asn ? data.asn : "");
    formData.append("phone", data.phone ? data.phone : "");

    formData.append("post_code", data.post_code ? data.post_code : "");
    formData.append("state", data.state ? data.state : "");
    formData.append("suburb", data.suburb ? data.suburb : "");
    formData.append(
      "street_address",
      data.street_address ? data.street_address : ""
    );

    formData.append(
      "bank_account_number",
      data.bank_account_number ? data.bank_account_number : ""
    );
    formData.append(
      "account_holder_name",
      data.account_holder_name ? data.account_holder_name : ""
    );
    formData.append("bsb", data.bsb ? data.bsb : "");
    formData.append("slug", data?.branding_name || "");
    formData.append("branding_text", data?.branding_details || "");

    // Call the API to update settings
    await spSettingUpdateRequest(formData);

    // Update local state with the new email
    setSettingData((prev) => ({
      ...prev,
      email: data.new_email, // Update the email with the new email
    }));

    // Reset the form fields
    reset({ ...settings, new_email: "" });
  };

  useEffect(() => {
    getSpSettingRequest();
  }, []);

  const handleImageError = (e, fname, lname) => {
    return (e.target.src = getNameProfile(fname, lname));
  };

  return (
    <div>
      <div className='min-h-full'>
        <SideBar />
        <div
          className={`${
            sideBarPadding
              ? "lg:pl-[4rem] duration-300"
              : "lg:pl-64 duration-300"
          }  flex flex-col`}
        >
          <Header
            title='SP Settings'
            breadcrumb={
              <Breadcrumb
                pages={SP_SETTING_BREADCRUMB(
                  SETTINGS_BREADCRUMB_PAGES.SP_SETTING
                )}
              />
            }
          />
          <section className='container mx-auto sm:px-6 lg:px-8 mt-3'>
            {!settingData || isLoading ? (
              <div
                className={`flex flex-col w-full min-h-screen bg-gray-50 items-center justify-center`}
              >
                <CircularLoader
                  classes='flex justify-center items-center'
                  classloader='loader-l'
                />
              </div>
            ) : (
              <div className='my-5 md:mt-0 md:col-span-2'>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='shadow sm:rounded-md sm:overflow-hidden'>
                    <div className='px-4 py-5 bg-white space-y-6 sm:p-6'>
                      <div className='grid grid-cols-2 gap-y-4 gap-x-5'>
                        <div>
                          <label className='block text-base font-medium text-gray-700'>
                            Profile Photo
                          </label>
                          <div className='mt-1 flex items-center'>
                            {profilePicture ? (
                              <img
                                className='object-cover w-10 h-10 rounded-full'
                                src={
                                  profilePicture
                                    ? URL.createObjectURL(profilePicture)
                                    : ""
                                }
                                alt=''
                              />
                            ) : (
                              <img
                                onError={(e) =>
                                  handleImageError(e, settingData.name, " ")
                                }
                                className='object-cover w-10 h-10 rounded-full'
                                src={
                                  settingData && settingData.profile_picture
                                    ? baseURLImage(settingData.profile_picture)
                                    : getNameProfile(settingData.name, " ")
                                }
                                alt=''
                              />
                            )}
                            <label
                              htmlFor='file-upload'
                              className='relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500 ml-5'
                            >
                              <span>Upload Image</span>

                              <input
                                id='file-upload'
                                name='file-upload'
                                type='file'
                                className='sr-only'
                                accept='image/*'
                                onChange={(e) =>
                                  setProfilePicture(e.target.files[0])
                                }
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className='grid grid-cols-2 gap-y-4 gap-x-5'>
                        <div>
                          <label
                            htmlFor='name'
                            className='capitalize block text-sm font-medium text-gray-700'
                          >
                            Business Name
                          </label>
                          <div className='mt-1'>
                            <input
                              maxLength={100}
                              type='text'
                              name='name'
                              id='name'
                              className='did-floating-input capitalize'
                              {...register("name", {
                                required: true,
                                maxLength: 254,
                              })}
                            />
                            {errors.name && (
                              <span className='text-red-500'>
                                This field is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor='phone'
                            className='block text-sm font-medium text-gray-700'
                          >
                            Phone Number
                          </label>
                          <div className='mt-1'>
                            <input
                              type='number'
                              // minLength={10}
                              maxLength={10}
                              name='phone'
                              id='phone'
                              className='did-floating-input'
                              {...register("phone", {
                                // minLength: 10,
                                maxLength: 10,
                                pattern: REGEX_PHONE_NUMBER,
                                onChange: (e) => {
                                  const sanitizedValue = e.target.value.replace(
                                    /\D/g,
                                    ""
                                  );
                                  const formattedValue = sanitizedValue.slice(
                                    0,
                                    10
                                  );
                                  e.target.value = formattedValue;
                                },
                              })}
                            />
                            {errors?.phone?.type === "required" ? (
                              errors.phone && (
                                <span className='text-red-500'>
                                  This field is required
                                </span>
                              )
                            ) : (
                              <>
                                {errors.phone?.type === "pattern" && (
                                  <span className='text-red-500'>
                                    {/* Phone number must start with 04, 1300 or
                                    1800 */}
                                    Please enter a valid phone number!
                                  </span>
                                )}
                                {errors.phone?.type === "minLength" && (
                                  <span className='text-red-500'>
                                    Please enter a valid phone number with the
                                    min length of 10
                                  </span>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                        <div>
                          <InputField
                            label='Organization Email'
                            setValue={setValue}
                            register={register}
                            type='text'
                            //value='email'
                            name='email'
                            readOnly
                            defaultValue={"email"}
                          />
                        </div>
                        <div>
                          <InputField
                            label='Login email address'
                            setValue={setValue}
                            register={register}
                            type='text'
                            name='new_email'
                          />
                        </div>

                        <div>
                          <label
                            htmlFor='abn'
                            className='block text-sm font-medium text-gray-700'
                          >
                            ABN
                          </label>
                          <div className='mt-1'>
                            <input
                              className='did-floating-input'
                              {...register("abn", {
                                required: true,
                                pattern: "[0-9]*",
                                maxLength: 11,
                                minLength: 11,
                                onChange: (e) =>
                                  (e.target.value = e.target.value
                                    .toString()
                                    .slice(0, 11)),
                              })}
                            />
                            {errors?.abn?.type === "required" ? (
                              <span className='text-red-500'>
                                This field is required
                              </span>
                            ) : errors.abn?.type === "pattern" ? (
                              <span className='text-red-500'>
                                Please enter a valid ABN
                              </span>
                            ) : errors.abn?.type === "maxLength" ||
                              errors.abn?.type === "minLength" ? (
                              <span className='text-red-500'>
                                Please enter a valid ABN with 11 digits
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor='asn'
                            className='block text-sm font-medium text-gray-700'
                          >
                            ACN
                          </label>
                          <div className='mt-1'>
                            <input
                              type='number'
                              name='asn'
                              id='asn'
                              className='did-floating-input'
                              {...register("asn", {
                                pattern: "[0-9]*",
                              })}
                            />
                            {errors?.asn?.type === "required" ? (
                              <span className='text-red-500'>
                                This field is required
                              </span>
                            ) : errors.asn?.type === "pattern" ? (
                              <span className='text-red-500'>
                                Please enter a valid number
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor=''
                            className='block text-sm font-medium text-gray-700'
                          >
                            Street Address
                          </label>
                          <div className='relative mt-1'>
                            <input
                              type='text'
                              name='street_address'
                              id='street_address'
                              className='did-floating-input '
                              {...register("street_address", {
                                maxLength: 254,
                              })}
                            />
                            {errors.street_address && (
                              <span className='text-red-500'>
                                This field is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor=''
                            className='block text-sm font-medium text-gray-700'
                          >
                            State
                          </label>
                          <div className='relative mt-1'>
                            <input
                              type='text'
                              name='state'
                              id='state'
                              className='did-floating-input '
                              {...register("state", {
                                maxLength: 254,
                              })}
                            />
                            {errors.state && (
                              <span className='text-red-500'>
                                This field is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor=''
                            className='block text-sm font-medium text-gray-700'
                          >
                            Suburb
                          </label>
                          <div className='relative mt-1'>
                            <input
                              type='text'
                              name='suburb'
                              id='suburb'
                              className='did-floating-input '
                              {...register("suburb", {
                                maxLength: 254,
                              })}
                            />
                            {errors.suburb && (
                              <span className='text-red-500'>
                                This field is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor='post_code'
                            className='block text-sm font-medium text-gray-700'
                          >
                            Postcode
                          </label>
                          <div className='mt-1'>
                            <input
                              type='number'
                              maxLength={4}
                              name='post_code'
                              id='post_code'
                              className='did-floating-input'
                              {...register("post_code", {
                                maxLength: 4,
                                pattern: "[0-9]*",
                                onChange: (e) => {
                                  e.target.value = e.target.value
                                    .toString()
                                    .slice(0, 4);
                                },
                              })}
                            />
                            {errors?.post_code?.type === "required" ? (
                              errors.post_code && (
                                <span className='text-red-500'>
                                  This field is required
                                </span>
                              )
                            ) : (
                              <>
                                {errors.post_code?.type === "pattern" && (
                                  <span className='text-red-500'>
                                    Please enter a valid number
                                  </span>
                                )}
                                {errors.post_code?.type === "maxLength" && (
                                  <span className='text-red-500'>
                                    Please enter a valid number
                                  </span>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  
                    <div className='px-4 py-3  bg-gray-50 sm:px-6 space-x-4'>
                      <div className='pt-3 text-xl text-start'>
                        Bank Details
                      </div>
                      <divc className='m-0'>
                        <div className='grid grid-cols-4 gap-y-4 gap-x-5'>
                          <div className='col-span-2'>
                            <label
                              htmlFor=''
                              className='block text-sm font-medium text-gray-700'
                            >
                              Account Name
                            </label>
                            <div className='relative mt-1'>
                              <input
                                type='text'
                                name='account_holder_name'
                                id='state'
                                placeholder='Account Title'
                                className='did-floating-input '
                                {...register("account_holder_name", {
                                  required: false,
                                  maxLength: 100,
                                })}
                              />
                              {errors.account_holder_name && (
                                <span className='text-red-500'>
                                  This field is required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className='col-span-1'>
                            <label
                              htmlFor='bsb'
                              className='block text-sm font-medium text-gray-700'
                            >
                              BSB
                            </label>
                            <div className='relative mt-1'>
                              <input
                                type='number'
                                name='bsb'
                                id='bsb'
                                placeholder='BSB'
                                className='did-floating-input'
                                onInput={(e) => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  );
                                }}
                                {...register("bsb", {
                                  required: false,
                                  maxLength: 6,
                                  pattern: "[0-9]*",

                                  onChange: (e) => {
                                    e.target.value = e.target.value
                                      .toString()
                                      .slice(0, 6);
                                  },
                                })}
                              />
                              {errors.bsb && (
                                <span className='text-red-500'>
                                  This field is required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className='col-span-1'>
                            <label
                              htmlFor='bank_account_number'
                              className='block text-sm font-medium text-gray-700'
                            >
                              Account Number
                            </label>
                            <div className='mt-1'>
                              <input
                                type='tel'
                                name='bank_account_number'
                                id='bank_account_number'
                                placeholder='Account Number'
                                className='did-floating-input'
                                onInput={(e) => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  );
                                }}
                                {...register("bank_account_number", {
                                  // required: "This field is required",
                                  required: false,
                                  pattern: {
                                    value: /^[0-9]+$/,
                                    message: "Please enter a valid number",
                                  },
                                })}
                              />

                              {errors.bank_account_number && (
                                <span className='text-red-500'>
                                  {errors.bank_account_number.message}
                                  This field is required
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </divc>
                  
                    </div>
                    <div className='px-4 py-3  sm:px-6 space-x-4'>
                      <div className='pt-3 text-xl text-start'>
                        Login Page Branding
                      </div>
                      <divc className='m-0'>
                        <div className='grid grid-cols-2 gap-y-4 gap-x-5'>
                          <div className='col-span-1'>
                            <label
                              htmlFor=''
                              className='block text-sm font-medium text-gray-700'
                            >
                             Slug 
                            </label>
                            <div className='relative mt-1'>
                              <input
                                type='text'
                                name='branding_name'
                               
                                placeholder=''
                                className='did-floating-input '
                                {...register("branding_name", {
                                  required: false,
                                  maxLength: 100,
                                })}
                              />
                            </div>
                          </div>

                          <div className='col-span-1'>
                            <label
                              htmlFor='bank_account_number'
                              className='block text-sm font-medium text-gray-700'
                            >
                            Branding Details
                            </label>
                            <div className='mt-1'>
                              <textarea
                              rows={3}
                                className='w-full px-4 py-2 rounded-lg border-gray-300'
                              
                                {...register("branding_details", { required: false })}
                              />
                            </div>
                          </div>
                        </div>
                      </divc>
                      <div className='text-right pt-3'>
                        <CustomButton
                          showLoading={settingUpdateLoading}
                          label='Save Changes'
                          isDisabled={
                            settingUpdateLoading || isUserGracePeriodOver
                          }
                          type='submit'
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </section>
        </div>
      </div>
    </div>
  );
};

export default SpSetting;
