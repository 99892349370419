import CustomButton from "components/common/Button/CustomButton";
import ModalSkeleton from "components/Modals/ModalSkeleton";
import { apiDeleteRequest } from "helpers/Requests";
import React, { useState } from "react";
import { toast } from "react-toastify";
import api from "store/services/auth.services";
import { getUrlRole } from "Utils/utils";

const DeleteAppointmentModal = (props) => {
  const {
    handleClose,
    open,
    setOpen,
    isUserGracePeriodOver,
    participantAppointmentDeleteLocally,
    appointmentId,
    refreshAppointments,
  } = props;

  const [request, setRequest] = useState({
    isRequested: false,
    isSuccess: false,
    isFailed: false,
  });

  const handleDelete = async () => {
    if (isUserGracePeriodOver) return;

    setRequest({ ...request, isRequested: true });
    try {
      const response = await apiDeleteRequest(
        `/${getUrlRole()}/participant-appointment/${appointmentId}`
      );

      if (response) {
        setRequest({ isRequested: false, isSuccess: true, isFailed: false });

        toast.success("Appointment Deleted Successfully!");
        handleClose();
        refreshAppointments();
      }
    } catch (err) {
      setRequest({ isRequested: false, isSuccess: false, isFailed: true });

      console.log("err", err);
    }
  };

  const modalFooter = () => (
    <>
      <CustomButton
        showLoading={request.isRequested}
        isDisabled={request.isRequested || isUserGracePeriodOver}
        label='Yes, delete this Appointment'
        clickHandler={handleDelete}
        variant='danger'
      />
      <CustomButton label='No, cancel changes' onClick={handleClose} />
    </>
  );

  return (
    <>
      <ModalSkeleton
        modalFooter={modalFooter()}
        isOpen={open}
        closeModal={() => setOpen(false)}
        cssClass='w-[55%]'
        title={"Delete Appointment"}
      >
        <div>
          <p className='text-gray-700'>
            Are you sure you want to delete this Appointment?
          </p>
        </div>
      </ModalSkeleton>
    </>
  );
};

export default DeleteAppointmentModal;
