import ACTIONS, { FETCH_STATUS } from "../constants";

export const workerAndParticipantListfetched = {
  pending: (data) => ({
    type: ACTIONS.FETCH_WORKER_AND_PARTICIPANT_LIST.PENDING,
    status: FETCH_STATUS.LOADING,
    calendarView: data.calendarView,
    search: data.search,
  }),
  success: (data) => ({
    type: ACTIONS.FETCH_WORKER_AND_PARTICIPANT_LIST.SUCCESS,
    data: data.data,
    isRefreshCall: data.isRefreshCall,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.FETCH_WORKER_AND_PARTICIPANT_LIST.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const fetchWorkerAndParticipantListRequest = (state) => ({
  type: ACTIONS.FETCH_WORKER_AND_PARTICIPANT_LIST_CALL,
  payload: state.data,
  calendarView:
    state.calendarView === "participant-view" ? "participant" : "worker",
  isRefreshCall: state?.isRefreshCall || false,
});

export const sortCalendarShiftsItem = () => ({
  type: ACTIONS.SORT_CALENDAR_SHIFTS_ITEM,
});

export const calendarDateChangeLocally = (data) => ({
  type: ACTIONS.CALENDAR_DATE_CHANGE_LOCALLY,
  data,
});

export const workerShiftCreatedOrUpdated = {
  pending: {
    type: ACTIONS.CREATE_OR_UPDATE_WORKER_SHIFT.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (obj) => ({
    type: ACTIONS.CREATE_OR_UPDATE_WORKER_SHIFT.SUCCESS,
    data: obj.response,
    calendarView: obj.calendarView,
    isUpdate: obj.isUpdate,
    isWorkerChange: obj.isWorkerChange,
    prevWorkerUUID: obj.prevWorkerUUID,
    status: FETCH_STATUS.SUCCESS,
    recurring: obj.recurring,
  }),
  error: (error) => ({
    type: ACTIONS.CREATE_OR_UPDATE_WORKER_SHIFT.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const createOrUpdateWorkerShiftRequest = (state, callback) => ({
  type: ACTIONS.CREATE_OR_UPDATE_WORKER_SHIFT_CALL,
  payload: state.data,
  calendarView: state.calendarView,
  isUpdate: state.isUpdate,
  isWorkerChange: state.isWorkerChange,
  prevWorkerUUID: state.prevWorkerUUID,
  name: state.name,
  callback,
});

export const workerAndParticipantShiftDeleteLocally = (data) => ({
  type: ACTIONS.WORKER_AND_PARTICIPANT_SHIFT_DELETE_LOCALLY,
  data,
});

export const workerAndParticipantShiftStatusChangedLocally = (data) => ({
  type: ACTIONS.WORKER_AND_PARTICIPANT_SHIFT_STATUS_CHANGED_LOCALLY,
  data,
});

export const workerAndParticipantShiftCopied = {
  pending: (data) => ({
    type: ACTIONS.WORKER_AND_PARTICIPANT_SHFIT_COPY.PENDING,
    data,
    status: FETCH_STATUS.LOADING,
  }),
  success: (obj) => ({
    type: ACTIONS.WORKER_AND_PARTICIPANT_SHFIT_COPY.SUCCESS,
    data: obj.response,
    calendarView: obj.calendarView,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.WORKER_AND_PARTICIPANT_SHFIT_COPY.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const workerAndParticipantShiftCopyRequest = (state) => ({
  type: ACTIONS.WORKER_AND_PARTICIPANT_SHFIT_COPY_CALL,
  payload: state,
});

export const participantListForTimeSheet = {
  pending: {
    type: ACTIONS.PARTICIPANT_LIST_FOR_TIMESHEET.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.PARTICIPANT_LIST_FOR_TIMESHEET.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.PARTICIPANT_LIST_FOR_TIMESHEET.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};
export const recurringMessageModal = (state) => ({
  type: ACTIONS.SHOW_RECURRING_SHIFT_MODAL,
  payload: state,
});
export const participantListForTimeSheetRequest = (state) => ({
  type: ACTIONS.PARTICIPANT_LIST_FOR_TIMESHEET_CALL,
  payload: state,
});

export const workerTimeSheetsFetched = {
  pending: (data) => ({
    type: ACTIONS.WORKER_TIMESHEETS.PENDING,
    status: FETCH_STATUS.LOADING,
    data,
  }),
  success: (data, paginationCall) => ({
    type: ACTIONS.WORKER_TIMESHEETS.SUCCESS,
    data,
    paginationCall,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.WORKER_TIMESHEETS.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const workerTimeSheetsFetchRequest = (state, isRefreshList) => ({
  type: ACTIONS.WORKER_TIMESHEETS_CALL,
  payload: state,
  isRefreshList,
});

export const workerTimeSheetsLocallySearch = (search) => ({
  type: ACTIONS.WORKER_TIMESHEETS_LOCALLY_SEARCH,
  search,
});

export const resetWorkerTimeSheets = () => ({
  type: ACTIONS.RESET_WORKER_TIMESHEETS,
});

export const setSelectedTimeSheetLocally = (data) => ({
  type: ACTIONS.SET_SELECTED_TIMESHEET_LOCALLY,
  data,
});

export const timeSheetCommentsFetched = {
  pending: (isFirstCall) => ({
    type: ACTIONS.TIMESHEET_COMMENTS_FETCHED.PENDING,
    isFirstCall,
    status: FETCH_STATUS.LOADING,
  }),
  success: (data) => ({
    type: ACTIONS.TIMESHEET_COMMENTS_FETCHED.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.TIMESHEET_COMMENTS_FETCHED.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const timeSheetCommentsFetchRequest = (state) => ({
  type: ACTIONS.TIMESHEET_COMMENTS_FETCH_CALL,
  payload: state,
});

export const timeSheetCommentCreated = {
  pending: {
    type: ACTIONS.TIMESHEET_COMMENT_CREATED.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.TIMESHEET_COMMENT_CREATED.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.TIMESHEET_COMMENT_CREATED.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const timeSheetCommentCreateRequest = (state) => ({
  type: ACTIONS.TIMESHEET_COMMENT_CREATE_CALL,
  payload: state,
});

export const timeSheetDataUpdated = {
  pending: {
    type: ACTIONS.TIMESHEET_DATA_UPDATED.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.TIMESHEET_DATA_UPDATED.SUCCESS,
    data: data.data,
    uuid: data.uuids,
    isNext: data.isNext,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.TIMESHEET_DATA_UPDATED.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const timeSheetDataUpdateRequest = (
  state,
  isNext = false,
  callback = () => {}
) => ({
  type: ACTIONS.TIMESHEET_DATA_UPDATE_CALL,
  payload: state,
  isNext,
  callback,
});

export const timeSheetStatusChanged = {
  pending: (data) => ({
    type: ACTIONS.TIMESHEET_STATUS_CHANGED.PENDING,
    status: FETCH_STATUS.LOADING,
    data,
  }),
  success: (data) => ({
    type: ACTIONS.TIMESHEET_STATUS_CHANGED.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.TIMESHEET_STATUS_CHANGED.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const timeSheetStatusChangeRequest = (state) => ({
  type: ACTIONS.TIMESHEET_STATUS_CHANGE_CALL,
  payload: state,
});

export const resetIsAnyPendingExistForWorker = () => ({
  type: ACTIONS.RESET_IS_ANY_PENDING_EXIST_FOR_WORKER,
});

export const workerTimeSheetExportList = {
  pending: {
    type: ACTIONS.WORKER_TIMESHEET_EXPORT_LIST.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.WORKER_TIMESHEET_EXPORT_LIST.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.WORKER_TIMESHEET_EXPORT_LIST.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const workerTimeSheetExportListRequest = (state) => ({
  type: ACTIONS.WORKER_TIMESHEET_EXPORT_LIST_CALL,
  payload: state,
});

export const singleTimesheetDetails = {
  pending: {
    type: ACTIONS.SINGLE_TIMESHEET_DETAILS.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.SINGLE_TIMESHEET_DETAILS.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.SINGLE_TIMESHEET_DETAILS.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const singleTimesheetDetailsRequest = (state) => ({
  type: ACTIONS.SINGLE_TIMESHEET_DETAILS_CALL,
  payload: state,
});

export const exportTimesheetToPayroll = {
  pending: (payrollType) => ({
    type: ACTIONS.EXPORT_TIMESHEET_TO_PAYROLL.PENDING,
    status: FETCH_STATUS.LOADING,
    payrollType,
  }),
  success: (data) => ({
    type: ACTIONS.EXPORT_TIMESHEET_TO_PAYROLL.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: ({ error, payrollType }) => ({
    type: ACTIONS.EXPORT_TIMESHEET_TO_PAYROLL.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
    payrollType,
  }),
};

export const exportTimesheetToPayrollRequest = (state) => ({
  type: ACTIONS.EXPORT_TIMESHEET_TO_PAYROLL_CALL,
  payload: state,
});

export const timesheetStatusChangedLocally = (data) => ({
  type: ACTIONS.TIMESHEET_STATUS_CHANGED_LOCALLY,
  data,
});

export const authenticationPayrollUpdateLocally = (data) => ({
  type: ACTIONS.AUTHENTICATED_PAYROLL_UPDATE_LOCALLY,
  data,
});

export const getRosterInvoicesRequest = (payload) => ({
  type: ACTIONS.GET_ROSTER_INVOICE_CALL,
  payload,
});

export const getRosterInvoices = {
  pending: {
    type: ACTIONS.GET_ROSTER_INVOICE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.GET_ROSTER_INVOICE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.GET_ROSTER_INVOICE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const updateCalendarSearchLocally = (data) => ({
  type: ACTIONS.UPDATE_CALENDER_SEARCH_LOCALLY,
  data,
});
