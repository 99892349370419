import CustomButton from "components/common/Button/CustomButton";
import ModalSkeleton from "components/Modals/ModalSkeleton";
import { apiDeleteRequest } from "helpers/Requests";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { getUrlRole } from "Utils/utils";

const DeleteFormModal = (props) => {
  const {
    open,
    setOpen,
    isUserGracePeriodOver,
    handleClose,
    role,
    uuid,
    selectedIncompleteForm,
    refreshFormList,
  } = props;

  const [request, setRequest] = useState({
    isRequested: false,
    isSuccess: false,
    isFailed: false,
  });

  const handleDelete = async () => {
    setRequest({ isRequested: true, isSuccess: false, isFailed: false });

    try {
      const response = await apiDeleteRequest(
        `/${getUrlRole()}/participant/incomplete-form-delete/${uuid}`,

        { form_uuid: uuid }
      );
      if (response.status === 200) {
        toast.success("Form deleted successfully!");

        handleClose();

        refreshFormList();
      } else {
        throw new Error("Failed to delete form");
      }
    } catch (error) {
      setRequest({ isRequested: false, isSuccess: false, isFailed: true });

      console.error("Failed to delete form:", error);

      toast.error("Failed to delete form. Please try again.");
    } finally {
      setRequest({ isRequested: false, isSuccess: false, isFailed: false });
    }
  };

  const modalFooter = () => (
    <>
      <CustomButton
        showLoading={request.isRequested}
        isDisabled={request.isRequested || isUserGracePeriodOver}
        label='Yes, delete Form'
        clickHandler={handleDelete}
        variant='danger'
      />
      <CustomButton label='No, cancel changes' onClick={handleClose} />
    </>
  );

  return (
    <>
      <ModalSkeleton
        modalFooter={modalFooter()}
        isOpen={open}
        closeModal={() => setOpen(false)}
        cssClass='w-[35%]'
        title={"Delete Form"}
      >
        <div>
          <p className='text-gray-700'>
            Are you sure you want to delete this Form?
          </p>
        </div>
      </ModalSkeleton>
    </>
  );
};

export default DeleteFormModal;
