import React, { useContext, useEffect, useMemo, useState } from "react";
import Accordion from "components/common/Accordian/Accordian";
import RoomDetails from "./SilRoomDetails";
import RoomDocs from "./RoomDocs";
import api from "store/services/sil.services";
import { useDispatch, useSelector } from "react-redux";
import {
  getSelectedSilAccommodation,
  // getSelectedSilAccommodationRooms,
} from "store/selector/sil.selector";
import { setSelectedSilAccommodationRoomLocally } from "store/actions/sil.actions";
import { toast } from "react-toastify";
import { capitalizeAllLetters } from "Utils/utils";
import { LoginContext } from "helpers/LoginContext";

const RoomAccordian = ({
  index,
  room,
  sequence,
  deleteSilRoomRequest,
  spParticipantsList,
  spParticipantsListLoader,
}) => {
  const { isUserGracePeriodOver } = useContext(LoginContext);

  const [isLoading, setIsLoading] = useState(false);
  const [roomDetails, setRoomDetails] = useState({});
  const selectedSilAccommodation = useSelector(getSelectedSilAccommodation);
  // const selectedSilAccommodationRooms = useSelector(
  //   getSelectedSilAccommodationRooms
  // );

  const [isupdateLoader, setIsUpdateLoader] = useState(false);
  const dispatch = useDispatch();

  // const notAssignedUsersOptions = useMemo(() => {
  //   const allParticipants = selectedSilAccommodation?.participantsList || [];
  //   const allWorkers = selectedSilAccommodation?.workersList || [];

  //   const assignedParticipants =
  //     selectedSilAccommodationRooms
  //       ?.flatMap((x) => x?.participants)
  //       ?.map((p) => p?.id) || [];

  //   const assignedWorkers =
  //     selectedSilAccommodationRooms
  //       ?.flatMap((x) => x?.workers)
  //       ?.map((w) => w?.id) || [];

  //   return {
  //     participants: allParticipants
  //       ?.map((x) => ({
  //         ...x,
  //         label: `${x.first_name} ${x.last_name}`,
  //         value: x.id,
  //       }))
  //       .filter(({ value }) => !assignedParticipants.includes(value)),

  //     workers: allWorkers
  //       ?.map((x) => ({
  //         ...x,
  //         label: `${x.first_name} ${x.last_name}`,
  //         value: x.id,
  //       }))
  //       .filter(({ value }) => !assignedWorkers.includes(value)),
  //   };
  // }, [selectedSilAccommodation, selectedSilAccommodationRooms]);

  // const participantsOptions = useMemo(() => {
  //   const roomParticipants =
  //     roomDetails?.participants?.map((x) => ({
  //       ...x,
  //       label: `${x.first_name} ${x.last_name}`,
  //       value: x.id,
  //     })) || [];

  //   const combinedParticipants = [
  //     ...(notAssignedUsersOptions?.participants || []),
  //     ...roomParticipants,
  //   ];

  //   return combinedParticipants.filter(
  //     (value, index, self) =>
  //       index === self?.findIndex((t) => t.id === value.id)
  //   );
  // }, [roomDetails, notAssignedUsersOptions?.participants]);

  useEffect(() => {
    const { participants, workers } = room || {};
    const { participants: currentParticipants, workers: currentWorkers } =
      roomDetails || {};

    const updatedRoomDetails = { ...roomDetails };

    let needsUpdate = false;

    if (participants?.length !== currentParticipants?.length) {
      updatedRoomDetails.participants = participants || [];
      needsUpdate = true;
    }

    if (workers?.length !== currentWorkers?.length) {
      updatedRoomDetails.workers = workers || [];
      needsUpdate = true;
    }

    if (needsUpdate) {
      setRoomDetails(updatedRoomDetails);
    }
  }, [room, roomDetails]);

  // const workersOptions = useMemo(() => {
  //   const roomWorkers =
  //     roomDetails?.workers?.map((x) => ({
  //       ...x,
  //       label: `${x.first_name} ${x.last_name}`,
  //       value: x.id,
  //     })) || [];

  //   const combinedWorkers = [
  //     ...(notAssignedUsersOptions?.workers || []),
  //     ...roomWorkers,
  //   ];

  //   return combinedWorkers.filter(
  //     (value, index, self) =>
  //       index === self?.findIndex((t) => t.id === value.id)
  //   );
  // }, [roomDetails, notAssignedUsersOptions?.workers]);

  const handleRoomDetail = async () => {
    if (!room?.uuid) return;
    try {
      setIsLoading(true);
      const response = await api.silRoomDetailApi({ uuid: room.uuid });
      setRoomDetails(response);
    } catch (err) {
      console.error("Error fetching room details:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const isRoomDetailsLoaded = roomDetails?.uuid && !isLoading;

  const handleUpdateRoomTitle = async (e, callback = null) => {
    try {
      if (isUserGracePeriodOver) {
        return;
      }
      setIsUpdateLoader(true);
      const response = await api.silRoomNameUpdateApi({
        name: e,
        uuid: room.uuid,
        room_uuid: room.uuid,
      });
      if (response) {
        dispatch(
          setSelectedSilAccommodationRoomLocally(
            {
              ...response,
            },
            room.uuid
          )
        );
        toast.success("Room name updated successfully!");
      }
      setIsUpdateLoader(false);
      if (callback) {
        callback(true);
      }
    } catch (err) {
      setIsUpdateLoader(false);
      console.log("err", err);
    }
  };

  const participantsOptions = useMemo(() => {
    return spParticipantsList?.participant?.data?.map((x) => ({
      label: capitalizeAllLetters(`${x.first_name} ${x.last_name}`),
      value: x.id,
      id: x.id,
      uuid: x.uuid,
    }));
  }, [spParticipantsList]);

  // console.log("spParticipantsList", spParticipantsList);

  return (
    <Accordion
      isLoading={isLoading}
      callbackOnOpen={handleRoomDetail}
      title={room?.name ? room?.name : `Room ${sequence + 1}`}
      key={index}
      handleEditTile={handleUpdateRoomTitle}
      updateLoader={isupdateLoader}
      isUserGracePeriodOver={isUserGracePeriodOver}
    >
      <div className='p-2 py-4 pr-5'>
        {isRoomDetailsLoaded && (
          <div className='grid grid-cols-12'>
            <div className='grid col-span-7 rounded-lg border border-gray-300 shadow-sm py-2 px-4'>
              <RoomDetails
                isUserGracePeriodOver={isUserGracePeriodOver}
                selectedSilAccommodation={selectedSilAccommodation}
                room={{
                  ...roomDetails,
                  cupboard: roomDetails?.cupboard === true ? "yes" : "no",
                  tv: roomDetails?.tv === true ? "yes" : "no",
                  participants: roomDetails?.participants?.map((x) => x.id),
                  workers: roomDetails?.workers?.map((x) => x.id),
                }}
                updateDetails={(values) => {
                  // console.log("values", values);
                  setRoomDetails((prev) => ({ ...prev, ...values }));
                }}
                // workersOptions={workersOptions}
                participantsOptions={participantsOptions}
                spParticipantsListLoader={spParticipantsListLoader}
                deleteSilRoomRequest={deleteSilRoomRequest}
                index={index}
              />
            </div>
            <div className='col-span-5'>
              <RoomDocs
                handleUpdateRoomDetails={setRoomDetails}
                room={roomDetails}
                index={index}
              />
            </div>
          </div>
        )}
      </div>
    </Accordion>
  );
};

export default RoomAccordian;
