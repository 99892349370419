import React, { useEffect } from "react";
import WorkerShiftCustomSelect from "./asyncSelect";
import {
  fetchOptionsForWorkerAndParticpant,
  parseBooleanVal,
} from "Utils/utils";
import SelectOption from "components/common/Select/Select";
import MultiSelectDropdown from "components/common/MultiSelectDropdown";

const WorkerSelection = (props) => {
  const {
    watchFormData,
    calendarView,
    selectedShift,
    workerOptions,
    register,
    setValue,
    control,
    watch,
    getSelectedOption,
    setSelectedOptions,
    selectedOptions,
    clearErrors,
    errors,
    isCallFromAddShift,
    setIsWorkerLoaded,
    setWorkerOptions,
    setParticipantOptions,
    checkDisabilityOfShiftStatus,
    checkDisabliltyOfShiftForWorker,
    checkRunningShiftStatus,
    handleChangeWorkerOrParticipant,
    userItem,
    isFetchRecordDetailLoader,
    isWorkerLoaded,
    setIsParticipantLoaded,
    selectedWorker,
    isOpenShift=false
  } = props;

  useEffect(()=>{
if(isOpenShift){
  setValue("worker_id",null)
}
  },[isOpenShift,watchFormData?.pt_id])
  return (
    <div className=''>
      <label className='text-gray-700 text-sm font-medium mb-1'>Worker</label>

     {/* Group Shift && Participant View && Not Selected Shift */}
     {watchFormData?.is_group_shift &&
        calendarView === "participant-view" &&
        isOpenShift && (
          <SelectOption
          isClearable={isCallFromAddShift}
          isValueChanges={(e, val) => {
            const isNotEmptyPtId =
              watchFormData?.pt_id !== null &&
              watchFormData?.pt_id !== "" &&
              watchFormData?.pt_id !== undefined;

            if (isNotEmptyPtId) {
              return;
            }

            if (userItem === null && e && calendarView === "worker-view") {
              setParticipantOptions([]);
              setValue("pt_id", null);
              handleChangeWorkerOrParticipant({
                type: "worker",
                uuid: val,
              }).then(() => setIsWorkerLoaded(true));
            }
          }}
          noOptionsMessage={"No Worker Assigned"}
          // noOptionsMessage='Group Shift (calendar view -> Worker and if-> participant view  not Add shift) '
          isRequired={false}
          register={register}
          setValue={setValue}
          clearErrors={clearErrors}
          name='worker_id'
          loading={isFetchRecordDetailLoader}
          isDisabled={
            checkDisabliltyOfShiftForWorker() ||
            checkDisabilityOfShiftStatus(selectedShift?.status)
            || (isCallFromAddShift && isOpenShift) || (selectedShift === null && isOpenShift)
            
          }
          cssClass='z-30'
          showIcon={true}
          isSearch={true}
          data={workerOptions}
          selectedOption={getSelectedOption("worker_id")}
          errorMessage={
            errors?.worker_id?.type === "required"
              ? "This field is required!"
              : null
          }
        />
        )}
      {/* Group Shift && Participant View && Selected Shift */}
      {watchFormData?.is_group_shift &&
        calendarView === "participant-view" &&
        selectedShift && !isOpenShift &&(
          <MultiSelectDropdown
            showIcon
            data={workerOptions}
            cssClass='z-30'
            name='worker_id'
            register={register}
            setValue={setValue}
            control={control}
            watch={watch}
            selectedOption={getSelectedOption("worker_id")}
            setSelectedOptions={setSelectedOptions}
            selectedOptions={selectedOptions}
            clearErrors={clearErrors}
            selectedShift={selectedShift}
            calendarView={calendarView === "participant-view"}
            isDisabled={
              checkDisabliltyOfShiftForWorker() ||
              // (!!selectedShift.worker_id &&
              //   checkDisabilityOfShiftStatus(selectedShift?.status))
              checkRunningShiftStatus(selectedShift?.status)
            }
            // calendarView={calendarView === "worker-view"}
            errorMessage={
              errors?.worker_id?.type === "required"
                ? "This field is required!"
                : null
            }
          />
        )}

      {/* Group Shift && Participant View && Not Selected Shift */}
      {watchFormData?.is_group_shift &&
        calendarView === "participant-view" &&
        !selectedShift &&  !isOpenShift && (
          <MultiSelectDropdown
            showIcon
            data={workerOptions}
            cssClass='z-30'
            name='worker_id'
            register={register}
            setValue={setValue}
            control={control}
            watch={watch}
            selectedOption={getSelectedOption("worker_id")}
            setSelectedOptions={setSelectedOptions}
            selectedOptions={selectedOptions}
            clearErrors={clearErrors}
            calendarView={calendarView === "participant-view"}
            isDisabled={
              checkDisabliltyOfShiftForWorker() ||
              checkDisabilityOfShiftStatus(selectedShift?.status) 
            }
            // calendarView={calendarView === "worker-view"}
            errorMessage={
              errors?.worker_id?.type === "required"
                ? "This field is required!"
                : null
            }
          />
        )}

      {/* Add Shift For Worker view */}
      {isCallFromAddShift && calendarView === "worker-view" && (
        <>
          <select
            className='hidden'
            {...register("worker_id", {
              value: watchFormData?.worker_id || null,
              required: true,
            })}
          />
          <WorkerShiftCustomSelect
            isClearable={true}
            isClearCall={async () => {
              if (watchFormData?.is_group_shift === true) {
                setValue("is_group_shift", null);
              }
              setValue("worker_id", null);
              setValue("pt_id", null);
              setIsWorkerLoaded(true);
              setIsParticipantLoaded(true);
              fetchOptionsForWorkerAndParticpant("worker")
                .then((response) => {
                  setWorkerOptions([...response]);
                  setIsWorkerLoaded(false);
                })
                .catch(() => setIsWorkerLoaded(false));

              fetchOptionsForWorkerAndParticpant("participant")
                .then((response) => {
                  setParticipantOptions([...response]);
                  setIsParticipantLoaded(false);
                })
                .catch(() => {
                  setIsParticipantLoaded(false);
                });
            }}
            selectFor='worker'
            defaultOptions={watchFormData?.pt_id ? workerOptions : null}
            clearErrors={clearErrors}
            name='worker_id'
            setValue={setValue}
            loading={isWorkerLoaded}
            isDisabled={
              watchFormData?.is_group_shift
                ? checkDisabliltyOfShiftForWorker() ||
                  checkDisabilityOfShiftStatus(selectedShift?.status) ||
                  isWorkerLoaded
                : checkDisabliltyOfShiftForWorker() ||
                  checkRunningShiftStatus(selectedShift?.status) ||
                  isWorkerLoaded 
            }
            selectedValue={watchFormData?.worker_id}
            errorMessage={
              errors?.worker_id?.type === "required"
                ? "This field is required!"
                : null
            }
            isValueChanges={(e, val) => {
              const clearWorkerAndFetchData = async () => {
                setParticipantOptions([]);
                setValue("pt_id", null);
                setIsParticipantLoaded(true);
                setIsWorkerLoaded(true);
                handleChangeWorkerOrParticipant({
                  type: "worker",
                  uuid: val.uuid,
                })
                  .then(() => {
                    setIsWorkerLoaded(false);
                    setIsParticipantLoaded(false);
                  })
                  .catch(() => {
                    setIsWorkerLoaded(false);
                    setIsParticipantLoaded(false);
                  });
              };
              const isNotEmptyPtId =
                watchFormData?.pt_id !== null &&
                watchFormData?.pt_id !== "" &&
                watchFormData?.pt_id !== undefined;
              if (isNotEmptyPtId) {
                if (parseBooleanVal(watchFormData?.is_group_shift)) {
                  clearWorkerAndFetchData().then();
                }
                setIsWorkerLoaded(true);
                handleChangeWorkerOrParticipant(
                  {
                    type: "worker",
                    uuid: val.uuid,
                  },
                  { isCheckParticipantExist: true }
                )
                  .then(() => {
                    setIsWorkerLoaded(false);
                  })
                  .catch(() => {
                    setIsWorkerLoaded(false);
                  });

                // if (val === null || isNotEmptyPtId) {
                // setParticipantOptions([]);
                // setValue("pt_id", null);
                // if (val?.uuid) {
                //   handleChangeWorkerOrParticipant({
                //     type: "worker",
                //     uuid: val.uuid,
                //     id: val.id,
                //   }).then(() => setIsWorkerLoaded(true));
                // }
                return;
              }
              if (
                userItem === null &&
                e &&
                calendarView === "worker-view" &&
                val
              ) {
                if (!isNotEmptyPtId || watchFormData?.is_group_shift) {
                  setParticipantOptions([]);
                  setValue("pt_id", null);
                }
                setIsWorkerLoaded(true);
                handleChangeWorkerOrParticipant({
                  type: "worker",
                  uuid: val.uuid,
                  id: val.id,
                })
                  .then(() => setIsWorkerLoaded(false))
                  .catch(() => setIsWorkerLoaded(false));
              }
            }}
          />
        </>
      )}

      {/*Group Shift (calendar view -> Worker and if-> participant view  not Add shift) */}
      {(!isCallFromAddShift || calendarView !== "worker-view") &&
        watchFormData?.is_group_shift &&
        calendarView === "worker-view" && (
          <SelectOption
            isClearable={isCallFromAddShift}
            isValueChanges={(e, val) => {
              const isNotEmptyPtId =
                watchFormData?.pt_id !== null &&
                watchFormData?.pt_id !== "" &&
                watchFormData?.pt_id !== undefined;

              if (isNotEmptyPtId) {
                return;
              }

              if (userItem === null && e && calendarView === "worker-view") {
                setParticipantOptions([]);
                setValue("pt_id", null);
                handleChangeWorkerOrParticipant({
                  type: "worker",
                  uuid: val,
                }).then(() => setIsWorkerLoaded(true));
              }
            }}
            noOptionsMessage={"No Worker Assigned"}
            // noOptionsMessage='Group Shift (calendar view -> Worker and if-> participant view  not Add shift) '
            isRequired={false}
            register={register}
            setValue={setValue}
            clearErrors={clearErrors}
            name='worker_id'
            loading={isFetchRecordDetailLoader}
            isDisabled={
              checkDisabliltyOfShiftForWorker() ||
              checkDisabilityOfShiftStatus(selectedShift?.status)
              
            }
            cssClass='z-30'
            showIcon={true}
            isSearch={true}
            data={workerOptions}
            selectedOption={getSelectedOption("worker_id")}
            errorMessage={
              errors?.worker_id?.type === "required"
                ? "This field is required!"
                : null
            }
          />
        )}

      {/* For Add Shift in Participant View && Not Group Shift  */}
      {!watchFormData?.is_group_shift &&
        (!isCallFromAddShift || calendarView !== "worker-view") && (
          <SelectOption
            noOptionsMessage={"No Worker Assigned"}
            // noOptionsMessage={
            //   "For Add Shift in Participant View && Not Group Shift"
            // }
            isClearCall={async () => {
              setValue("worker_id", null);
              setValue("pt_id", null);
              setIsWorkerLoaded(true);
              setIsParticipantLoaded(true);
              fetchOptionsForWorkerAndParticpant("worker")
                .then((response) => {
                  setWorkerOptions([...response]);
                  setIsWorkerLoaded(false);
                })
                .catch(() => setIsWorkerLoaded(false));
              fetchOptionsForWorkerAndParticpant("participant")
                .then((response) => {
                  setParticipantOptions([...response]);
                  setIsParticipantLoaded(false);
                })
                .catch(() => setIsParticipantLoaded(false));
            }}
            isValueChanges={(e, val) => {
              const isNotEmptyPtId =
                watchFormData?.pt_id !== null &&
                watchFormData?.pt_id !== "" &&
                watchFormData?.pt_id !== undefined;
              if (
                isNotEmptyPtId &&
                watchFormData?.worker_id !== val &&
                watchFormData?.worker_id === null
              ) {
                return;
              }

              if (userItem === null && e) {
                if (isNotEmptyPtId) {
                  setParticipantOptions([]);
                  setValue("pt_id", null);
                }
                setIsParticipantLoaded(true);
                handleChangeWorkerOrParticipant({
                  type: "worker",
                  uuid: val,
                }).then(() => setIsParticipantLoaded(false));
              }
            }}
            isRequired={false}
            register={register}
            setValue={setValue}
            clearErrors={clearErrors}
            name='worker_id'
            loading={isFetchRecordDetailLoader || isWorkerLoaded}
            isDisabled={
              checkDisabliltyOfShiftForWorker() ||
              checkRunningShiftStatus(selectedShift?.status) ||
              isWorkerLoaded || watchFormData?.single_open_shift
            }
            cssClass='z-30'
            showIcon={true}
            isSearch={true}
            isClearable={isCallFromAddShift}
            data={workerOptions}
            selectedOption={getSelectedOption("worker_id")}
            errorMessage={
              errors?.worker_id?.type === "required"
                ? "This field is required!"
                : null
            }
          />
        )}
    </div>
  );
};

export default WorkerSelection;
