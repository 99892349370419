import React, { useEffect, useState } from "react";
import {
  URL_PARTICIPANTS_SHIFT_NOTES,
  URL_PARTICIPANTS_SHIFT_NOTES_STANDARD_USER,
} from "store/services/URL";
import { apiPostRequest } from "helpers/Requests";
import { useParams } from "react-router-dom";
import PaginationComponent from "components/common/Pagination/index";
import { emptyPaginationObj, USER_ROLE } from "Constants/constant";
import CircularLoader from "../../../common/CircularLoader/CircularLoader";
import {
  addMonths,
  baseURLImage,
  getNameProfile,
} from "../../../../Utils/utils";
import DateRangePickerNew from "../../../common/DateRangePicker/DateRangePickerNew";
import SelectOption from "components/common/Select/Select";
import { roleAccess } from "../../../../helpers/RolesPermission";
import {
  BLK_PORTAL_PREMISSION_KEYS_CHILDREN,
  PERMISSION_KEYS,
} from "../../../../store/constants";

import moment from 'moment';
import {toast} from "react-toastify";

const ShiftNotes = ({participantInfo}) => {

  // for api notes
  const [shiftNotes, setShiftNotes] = useState([]);
  // for date range
  const [startDate, setStartDate] = useState(() => {
   const today = new Date();
   today.setDate(today.getDate() - 90);
   return today;
  });
  const [endDate, setEndDate] = useState(new Date());
  // state use for validating user not to select date range beyond 3 months.
  const [selectedFromDate, setSelectedFromDate] = useState(null);

  // pagination
  const [pagination, setPagination] = useState(emptyPaginationObj);
  const [page, setPage] = useState(1);

  // loading state
  const [isLoading, setIsLoading] = useState(false);

  // for setting and selecting the worker
  const [selectedWorker, setSelectedWorker] = useState({
    label: "All",
    value: "all",
  });

  const [workerList, setWorkerList] = useState();

  // take uuid of the participants
  const { uuid } = useParams();

  const formatDateTime = (dateTimeStr) => {
    if (!dateTimeStr) return "Not available";
    return {
      date: moment(dateTimeStr).format("DD/MM/YYYY hh:mm A"),
    };
  };

  const createWorkerList = (workersData) => {
    let workers = [{ label: "All", value: "all" }];
    let addedWorkers = {};

    workersData.forEach((worker) => {
      if (!addedWorkers[worker.user_id]) {
        workers.push({
          label: `${worker.first_name} ${worker.last_name}`,
          value: worker.user_id,
          id: worker.id
        });
        addedWorkers[worker.id] = true;
      }
    });

    workers.sort((a, b) => {
      if (a.value === "all") return -1;
      if (b.value === "all") return 1;
      return a.label.localeCompare(b.label);
    });

    return workers;
  };


  const getShiftNotes = async () => {
    // don't call any API when start date and end date is null
    if (!startDate || !endDate) return;
    setIsLoading(true);

    try {
      const isSpUser = roleAccess([USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager]);
      const endpoint = !isSpUser
          ? `${URL_PARTICIPANTS_SHIFT_NOTES_STANDARD_USER}`
          : `${URL_PARTICIPANTS_SHIFT_NOTES}`;

      // format to fetch data form API
      const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
      const formattedEndDate = moment(endDate).format('YYYY-MM-DD');

      const payload = {
        uuid,
        start_date: formattedStartDate,
        end_date: formattedEndDate,
        page: page,
        worker_id: selectedWorker?.value === "all"
            ? null
            : parseInt(selectedWorker?.value),
      };

      const response = await apiPostRequest(endpoint, payload);

      if (response.status === 200) {

        const notes = response.data.data.shift_notes;
        let  paginationData = response.data.data.pagination;

        if (selectedWorker?.value !== "all") {
          // filter notes based on worker selection
          const filteredNotes = notes.filter(
              (note) => note.worker_id === parseInt(selectedWorker?.id)
          );
          // update page as per filter data
          paginationData = {
            ...paginationData,
            total: notes.length,
            last_page: Math.ceil(notes.length / paginationData.per_page),
          };
          setShiftNotes(filteredNotes);
        } else {
          setShiftNotes(notes);
        }
        setPagination(paginationData);
      }
    } catch (error) {
      toast.error("Error fetching shift notes",error);

    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (participantInfo?.sp_workers?.length > 0) {
      const workersList = createWorkerList(participantInfo.sp_workers);
      setWorkerList(workersList);
    }
  }, [participantInfo?.sp_workers]);

  // Fetch notes when page, dates change, or worker is selected
  useEffect(() => {
    getShiftNotes();
  }, [page, startDate, endDate, selectedWorker]);

  // Event handlers
  const handleDateChange = (dates) => {
    if (dates?.fromDate && dates?.toDate) {
      setStartDate(dates.fromDate);
      setEndDate(dates.toDate);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const handleCancel = () => {
    setStartDate(null);
    setEndDate(null);
    setShiftNotes([])
    setPage(1);
  };

  return (
    <>
      <div>
        {(roleAccess(
          [USER_ROLE.Sp_Standard_User],
          PERMISSION_KEYS.PARTICIPANT,
          BLK_PORTAL_PREMISSION_KEYS_CHILDREN.PARTICIPANT.CASE_NOTES.STORE
        ) ||
          roleAccess([USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager])) && (
          <div>
            <div className="py-4">
              {/* Action elements */}
              <div className="bg-gray-50 shadow-md rounded-lg p-6 sm:flex sm:items-center sm:justify-between space-y-6 sm:space-y-0">
                <div className="flex flex-col sm:flex-row sm:items-center space-y-3 sm:space-y-0 sm:space-x-4 w-1/2">
                  <label
                      htmlFor="dateRange"
                      className="text-sm font-semibold text-gray-600 whitespace-nowrap"
                  >
                    Filter By Shift Date : (Max 3 Months)
                  </label>
                  <div className="flex-grow">
                    <DateRangePickerNew
                        fromDate={startDate}
                        toDate={endDate}
                        handleChange={handleDateChange}
                        handleCancel={handleCancel}
                        setSelectedFromDate={setSelectedFromDate}
                        maxDate={addMonths(selectedFromDate, 3)}

                    />
                  </div>
                </div>

                <div
                    className="flex flex-col sm:flex-row sm:items-center w-1/2 justify-end space-y-3 sm:space-y-0 sm:space-x-4">
                  <label
                    htmlFor="search"
                    className="text-sm font-semibold text-gray-600 whitespace-nowrap"
                  >
                    Filter By Worker:
                  </label>
                  <div className="w-64">
                    <SelectOption
                      name="worker_id"
                      isSearch={true}
                      data={workerList}
                      selectedOption={selectedWorker}
                      handleChange={(e) => {
                        setSelectedWorker(e);
                        setPage(1);
                      }}
                    />
                  </div>
                </div>
              </div>

              {/* Display notes */}
              <div className="lg:grid lg:grid-cols-12 lg:gap-x-16">
                {isLoading ? (
                  <div className="flex justify-center items-center w-full bg-gray-50 lg:col-span-12 xl:col-span-12">
                    <CircularLoader
                      classes="flex justify-center items-center"
                      classloader="loader-l"
                    />
                  </div>
                ) : shiftNotes.length > 0 ? (
                  <ol className="mt-6 mb-6 shadow-md rounded-xl lg:col-span-12 xl:col-span-12 max-h-64 md:max-h-80 lg:max-h-96 overflow-y-auto custom-scroll">
                    {shiftNotes.map((note, index) => (
                      <li
                        className={
                          index % 2
                            ? "bg-white hover:bg-gray-100"
                            : "bg-gray-50 hover:bg-gray-100"
                        }
                        key={index}
                      >
                        <div className="flex space-x-3 py-4 px-6">
                          <div className="flex-shrink-0">
                            <img
                              alt="avatar"
                              className="h-10 w-10 rounded-full object-cover"
                              src={
                                note.avatar
                                  ? baseURLImage(note.avatar)
                                  : getNameProfile(
                                      note?.worker_first_name || " ",
                                      note?.worker_last_name
                                    )
                              }
                            />
                          </div>

                          <div className="flex-1">
                            <div className="flex justify-between items-center">
                              <div
                                className="mt-2 text-sm space-x-2"
                                style={{ textAlign: "right" }}
                              >
                                <div className="flex flex-wrap gap-2 justify-end">

                                  <strong>
                                    {note.worker_first_name ||
                                      "N/A"}{" "}
                                    {note.worker_last_name ||
                                      "N/A"}{" "}
                                    | Schedule Time :{" "}
                                    { formatDateTime(note.worker_start_time).date}{" "} to { formatDateTime(note.worker_end_time).date}{" "}
                                  </strong>
                                </div>
                              </div>
                            </div>

                            <div className="mt-1 text-sm text-gray-700">
                              <p style={{ wordBreak: "break-word" }}>
                                {(
                                  note.worker_notes || "No notes available"
                                ).replace(/^\w/, (c) => c.toUpperCase())}
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ol>
                ) : (
                  <div className=" col-span-12 py-6 space-x-2 flex justify-center items-center">
                    <div className="flex justify-center items-center space-x-4">
                      <p className="font-medium text-red-500">
                        No Notes Found!
                      </p>
                    </div>
                  </div>
                )}
              </div>

              <div className="mt-3">
                { shiftNotes.length > 0 && (
                    <PaginationComponent
                        page={pagination.current_page}
                        activeListLength={shiftNotes}
                        limit={10}
                        totalList={pagination.total}
                        handlePagination={(newPage) => {
                          setPage(newPage);
                        }}
                        totalPages={pagination.last_page}
                    />
                )}

              </div>
            </div>
          </div>
        )}{" "}
      </div>
    </>
  );
};

export default ShiftNotes;
