import React, { useContext, useEffect, useState } from "react";
import RoomAccordian from "./components/RoomAccordian";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import CustomButton from "components/common/Button/CustomButton";
import { BiPlus } from "react-icons/bi";
import ConfirmationModal from "components/common/Modal/ConfirmationModal";
import api from "store/services/sil.services";
import { toast } from "react-toastify";
import { LoginContext } from "helpers/LoginContext";

const SilRooms = ({
  selectedAccommodationInfo,
  fetchAllSilRoomsListRequest,
  selectedSilAccommodationRooms,
  silAccommodationRoomListLoading,
  setSelectedSilAccommodationRoomVacantLocally,
  deleteSilRoomRequest,
  isCallFromHeader = false,
  getSPParticipantsListRequest,
  spParticipantsListLoader,
  spParticipantsList,
}) => {
  const { isUserGracePeriodOver } = useContext(LoginContext);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isRoomCreateLoading, setIsRoomCreateLoading] = useState(false);

  useEffect(() => {
    if (
      selectedAccommodationInfo &&
      selectedAccommodationInfo.uuid &&
      !isCallFromHeader
    ) {
      fetchAllSilRoomsListRequest({ uuid: selectedAccommodationInfo?.uuid });
    }
    getSPParticipantsListRequest({ filters: { per_page: 1000, page: 1 } });
  }, []);

  const handleCreateRoom = async () => {
    try {
      setIsRoomCreateLoading(true);
      const response = await api.silRoomCreateApi({
        home_uuid: selectedAccommodationInfo?.uuid,
      });
      if (response) {
        setIsOpenModal(null);
        const homeUpdatedData = await api.silAccommodationDetailApi(
          selectedAccommodationInfo?.uuid
        );
        toast.success("Room created successfully!");
        fetchAllSilRoomsListRequest({ uuid: selectedAccommodationInfo?.uuid });
        if (homeUpdatedData) {
          setSelectedSilAccommodationRoomVacantLocally(homeUpdatedData);
        }
      }
      setIsRoomCreateLoading(false);
      setIsOpenModal(null);
    } catch (err) {
      setIsRoomCreateLoading(false);
      toast.error(err?.message || "Something went wrong!");
      console.log("err", err);
    }
  };

  if (
    isCallFromHeader &&
    selectedAccommodationInfo &&
    selectedAccommodationInfo?.uuid
  ) {
    return (
      <>
        <CustomButton
          isDisabled={isUserGracePeriodOver}
          label='Add Room'
          Icon={<BiPlus />}
          clickHandler={() => {
            if (isUserGracePeriodOver) return;
            setIsOpenModal(true);
          }}
        />

        {isOpenModal && (
          <ConfirmationModal
            confirmBtnLoader={isRoomCreateLoading}
            isNew={true}
            showModal={isOpenModal}
            submitHandler={() => {
              if (isUserGracePeriodOver) return;
              handleCreateRoom();
            }}
            closeModalHandler={() => setIsOpenModal(null)}
            title='Add Room'
            heading='Are you sure you want to create a new room?'
            cancelBtnLabel={"No"}
            confirmBtnLabel={"Yes"}
            variantConfirmBtn={"primary"}
          />
        )}
      </>
    );
  }

  return (
    <div className='space-y-6'>
      {silAccommodationRoomListLoading ? (
        <div className='px-0 flex justify-center items-center min-h-screen bg-gray-50'>
          <CircularLoader
            classes='flex justify-center items-center'
            classloader='loader-l'
          />
        </div>
      ) : (
        selectedSilAccommodationRooms?.map((room, index) => (
          <RoomAccordian
            key={index}
            sequence={index}
            room={room}
            index={room.uuid}
            deleteSilRoomRequest={deleteSilRoomRequest}
            spParticipantsListLoader={spParticipantsListLoader}
            spParticipantsList={spParticipantsList}
          />
        ))
      )}
    </div>
  );
};

export default SilRooms;
