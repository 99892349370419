import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { getUser } from "store/selector/auth.selector";
import CustomButton from "components/common/Button/CustomButton";
import SwitchInput from "components/common/Input/SwitchInput";
import ModalSkeleton from "components/Modals/ModalSkeleton";
import { USER_ROLE } from "Constants/constant";
import { apiFormDataRequest } from "helpers/Requests";
import {
  baseURLImage,
  getExtension,
  getFileFromServerAsFileType,
  getNameProfile,
  getUrlRole,
  parseBooleanVal,
} from "Utils/utils";
import { roleAccess } from "helpers/RolesPermission";
import { toast } from "react-toastify";
import MultiFileInput from "components/common/FileUpload/MultiFileInput";
import Loader from "components/common/Loader/Loader";
import api from "store/services/sp.services";
import { cloneDeep } from "lodash";
import {
  BASE_URL,
  URL_SIL_ACCOMMODATION_CASE_NOTE_UPDATE,
} from "store/services/URL";
import { LoginContext } from "helpers/LoginContext";

const EditCaseNoteModal = ({
  isOpen,
  selectedComment,
  onCloseModal,
  fetchNotes,
  setFetchNotes,
  participantId,
  callFrom = "",
  participantIdUUID,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    watch,
  } = useForm();

  const watchFormData = watch();
  const { isUserGracePeriodOver } = useContext(LoginContext);
  const [isUpdating, setIsUpdating] = useState(false);
  const currentUser = useSelector(getUser);

  const [isFileDeleteLoader, setIsFileDeleteLoader] = useState(null);
  const [fileData, setFilesData] = useState([]);
  useEffect(() => {
    return () => {
      reset({});
      setIsUpdating(false);
    };
  }, []);

  const [fetchServerFiles, setFetchServerFiles] = useState(false);

  // useEffect(()=>{
  // if(watchFormData){
  //   setFilesData([watchFormData])
  // }

  // },[watchFormData.attachments])
  useEffect(() => {
    if (selectedComment) {
      async function fetchAttachments() {
        try {
          const serverFiles = await Promise.all(
            selectedComment.attachments?.map(async (item) => {
              const fileName =
                callFrom === "silProfile" ? item?.name : item?.original_name;

              const file =
                callFrom === "silProfile"
                  ? item?.attachment
                  : item?.attachments;
              try {
                setFetchServerFiles(true);
                const response = await getFileFromServerAsFileType(
                  baseURLImage(file),
                  fileName
                );
                setFetchServerFiles(false);
                return { file: response, ...item };
              } catch (error) {
                setFetchServerFiles(false);
                console.error(
                  `Error fetching file from ${baseURLImage(fileName)}:`,
                  error
                );
                // return null;
                return { file: null, ...item };
              }
            }) || []
          );

          reset({
            id: selectedComment?.id,
            uuid: selectedComment?.uuid,
            notes: selectedComment.notes || "",
            attachments: serverFiles.filter((file) => file !== null) || [],
            show_to_worker: parseBooleanVal(selectedComment.show_to_worker),
            worker_to_worker: parseBooleanVal(selectedComment.worker_to_worker),
          });
        } catch (error) {
          console.error("Error fetching attachments:", error);
        }
      }

      fetchAttachments();
    }
  }, [selectedComment, reset]);

  const handleImageError = (e, fname, lname) => {
    return (e.target.src = getNameProfile(fname, lname));
  };

  const handleUpdateCaseNote = async (data) => {
    setIsUpdating(true);
    try {
      const formData = new FormData();
      if (callFrom === "silProfile") {
        formData.append("home_uuid", participantIdUUID);
      } else {
        formData.append("pt_id", participantId);
      }
      formData.append("notes", data.notes);
      const fileToUpload = data?.attachments?.filter(
        (fl) => fl instanceof File
      );
      const preparFilesData =
        fileToUpload?.length > 0 &&
        Array.isArray(fileData) &&
        fileData.length > 0 &&
        fileData.map((item, index) => ({
          key: item?.key,
          ext: getExtension(fileToUpload[index].type),
          name: fileToUpload[index].name,
        }));

      if (preparFilesData && preparFilesData?.length > 0) {
        preparFilesData?.forEach((file, index) => {
          formData.append(`attachments[${index}][key]`, file.key);
          formData.append(`attachments[${index}][ext]`, file.ext);
          formData.append(`attachments[${index}][name]`, file.name);
        });
      }

      if (roleAccess([USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager])) {
        formData.append("show_to_worker", data?.show_to_worker);
      } else if (roleAccess([USER_ROLE.Sp_Standard_User])) {
        formData.append("worker_to_worker", data?.worker_to_worker);
      }

      if (data.uuid) {
        formData.append("uuid", data.uuid);
      }
      const response =
        callFrom === "silProfile"
          ? await apiFormDataRequest(
              `${URL_SIL_ACCOMMODATION_CASE_NOTE_UPDATE.replace(
                BASE_URL,
                ""
              )}/${selectedComment?.uuid}`,
              formData
            )
          : await apiFormDataRequest(`/${getUrlRole()}/case-note`, formData);
      const prevCaseNotes = cloneDeep(fetchNotes);
      const reponseData =
        callFrom === "silProfile"
          ? response?.data?.note
          : response?.data?.case_notes;
      const index = prevCaseNotes?.findIndex((e) => e.id === data.id);
      if (index !== -1) {
        prevCaseNotes.splice(index, 1, {
          ...reponseData,
        });
        setFetchNotes([...prevCaseNotes]);
        toast.success(
          `${
            callFrom === "silProfile" ? "Note" : "Case note"
          } updated successfully`
        );
      }
      onCloseModal();
      setIsUpdating(false);
    } catch (err) {
      setIsUpdating(false);
      console.error("Error updating case note:", err);
      toast.error(
        err?.message ||
          "An error occurred while updating case note. Please try again later."
      );
    }
  };

  const modalUpdateFooterButton = () => (
    <div className='flex space-x-2'>
      <CustomButton
        clickHandler={() => {
          if (isUserGracePeriodOver) return;
          const buttonRef = document.getElementById(
            "updateParticipantCaseNoteButton"
          );
          if (buttonRef) {
            buttonRef.click();
          }
        }}
        type='button'
        isDisabled={
          isUpdating ||
          fetchServerFiles ||
          isFileDeleteLoader ||
          isUserGracePeriodOver
        }
        showLoading={isUpdating}
        label='Update'
        variant='primary'
      />
      <CustomButton
        isDisabled={isUpdating || isFileDeleteLoader}
        clickHandler={onCloseModal}
        type='button'
        label='Close'
        variant='danger'
      />
    </div>
  );

  const handleDeleteItemCall = (item, callback) => {
    try {
      setIsFileDeleteLoader(item?.id ? item?.file?.name : item?.name);
      api
        .deleteCaseNoteAttachment(
          selectedComment?.uuid,
          item.id,
          getUrlRole(),
          callFrom
        )
        .then(() => {
          setIsFileDeleteLoader(null);
          const prevCaseNotes = cloneDeep(fetchNotes);
          const index = prevCaseNotes?.findIndex(
            (e) => e.uuid === selectedComment?.uuid
          );
          if (index !== -1) {
            if (callFrom === "silProfile") {
              prevCaseNotes.splice(index, 1, {
                ...prevCaseNotes[index],
                attachment: prevCaseNotes[index]?.attachment?.filter(
                  (x) => x.id !== item.id
                ),
              });
            } else {
              prevCaseNotes.splice(index, 1, {
                ...prevCaseNotes[index],
                attachments: prevCaseNotes[index]?.attachments?.filter(
                  (x) => x.id !== item.id
                ),
              });
            }
            setFetchNotes([...prevCaseNotes]);
          }
          if (callback) callback();
        })
        .catch((error) => {
          setIsFileDeleteLoader(null);
          toast.error(error?.message || "Something Went Wrong!");
        });
    } catch (err) {
      setIsFileDeleteLoader(null);
      toast.error(err?.message || "Something Went Wrong!");
      console.log("err", err);
    }
  };

  return (
    <>
      <ModalSkeleton
        isOpen={isOpen}
        closeModal={() => {
          if (isFileDeleteLoader !== null) return;
          onCloseModal();
        }}
        modalFooter={modalUpdateFooterButton()}
        cssClass='w-[50%]'
        title={callFrom === "silProfile" ? "Edit Note" : "Edit CaseNote"}
      >
        <div className='bg-gray-50 px-4 py-6 sm:px-6'>
          <div className='flex space-x-3'>
            {currentUser && (
              <div className='flex-shrink-0'>
                <img
                  onError={(e) =>
                    handleImageError(e, currentUser.name || " ", " ")
                  }
                  className='h-10 w-10 rounded-full object-cover'
                  src={
                    currentUser.profile_picture
                      ? baseURLImage(currentUser.profile_picture)
                      : getNameProfile(currentUser.name || " ", " ")
                  }
                  alt='profilePicture'
                />
              </div>
            )}
            <div className='min-w-0 flex-1'>
              <form onSubmit={handleSubmit(handleUpdateCaseNote)}>
                <div>
                  <textarea
                    {...register("notes", {
                      required: "Case note is required",
                    })}
                    rows={3}
                    className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                  />
                  {errors.notes && (
                    <span className='text-red-600 text-sm'>
                      {errors.notes.message}
                    </span>
                  )}
                </div>
                <br />
                <div className='col-span-1'>
                  <label className='did-floating-label text-start'>
                    Attachment
                  </label>
                  <div>
                    {fetchServerFiles ? (
                      <Loader isSmallView={true} cssClass={"text-black"} />
                    ) : (
                      <MultiFileInput
                        type='casenote'
                        isFileUploadToS3={callFrom === "silProfile"}
                        handleFile={(e) => {
                          setValue("attachments", e);
                        }}
                        size='1'
                        files={watchFormData?.attachments}
                        handleDelete={(e) => {
                          setValue("attachments", e);
                        }}
                        handleDeleteItem={handleDeleteItemCall}
                        showDragAndDrop={false}
                        deleteFileLoader={isFileDeleteLoader}
                        isDisabled={isFileDeleteLoader}
                        filenameLimit={150}
                        setFilesData={setFilesData}
                        setIsSubmitting={setIsUpdating}
                      />
                    )}
                  </div>
                </div>
                <br />
                <div className='mt-3 flex items-center justify-between'>
                  {callFrom !== "silProfile" && (
                    <div className='flex gap-x-4'>
                      {roleAccess([
                        USER_ROLE.Sp_Admin,
                        USER_ROLE.Sp_manager,
                      ]) && (
                        <SwitchInput
                          handleChange={(checked) =>
                            setValue("show_to_worker", Boolean(checked))
                          }
                          checked={watchFormData?.show_to_worker}
                          label='Show to worker'
                        />
                      )}

                      {roleAccess([USER_ROLE.Sp_Standard_User]) && (
                        <SwitchInput
                          label='Show to other workers'
                          checked={watchFormData?.worker_to_worker}
                          handleChange={(checked) =>
                            setValue("worker_to_worker", Boolean(checked))
                          }
                        />
                      )}
                    </div>
                  )}
                </div>

                <button
                  className='hidden sr-only'
                  type='submit'
                  id={`updateParticipantCaseNoteButton`}
                />
              </form>
            </div>
          </div>
        </div>
      </ModalSkeleton>
    </>
  );
};

export default EditCaseNoteModal;
