import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../common/Button/CustomButton";
import ModalSkeleton from "../../../Modals/ModalSkeleton";
import { LOADER_STATUS } from "../../../../Constants/constant";
import { getCreateAppointmentLoading } from "../../../../store/selector/participant.selector";
import { participantAppointmentCreateCall } from "../../../../store/actions/participant.actions";
import SelectOption from "components/common/Select/Select";
import {
  getSPWorkers,
  getSPSupportCoordinators,
  getSpWorkers,
  getSpSupportCoordinators,
} from "store/selector/sp.selector";
import { getSPWorkerRequest } from "store/actions/sp.actions";
import SwitchInput from "components/common/Input/SwitchInput";

const Appointment = ({
  isOpen,
  closeModal,
  participantInfo,
  appointment,
  isUpdate = false,
  updateAppointment,
  isUserGracePeriodOver,
}) => {
  const pt_workers = [...participantInfo?.sp_workers,...participantInfo?.sp_support_coordinators];
  const unique_pt_workers = pt_workers?.filter((worker, index, self) =>
      index === self.findIndex((w) => w.id === worker.id)
  );
  const participant_assigned_workers = unique_pt_workers.map(coordinator => ({
    uuid: coordinator.uuid,
    label: `${coordinator.first_name} ${coordinator.last_name}`,
    first_name: coordinator.first_name,
    last_name: coordinator.last_name,
    photo: coordinator.photo,
    status: true, // Always true
    value: coordinator.id
  }));

  const pt_id = participantInfo.id;
  const dispatch = useDispatch();
  const isLoading = useSelector(getCreateAppointmentLoading);

  const [createLoader, setCreateLoader] = useState(LOADER_STATUS.NA);
  const [assignedWorkers, setAssignedWorkers] = useState([]);
  const [enabled, setEnabled] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
    watch,
  } = useForm();
  const watchFormData = watch();

  useEffect(() => {
    if (isOpen) {
      clearErrors();

      reset();

      if (isUpdate && appointment) {
        const initialWorkers = (appointment.spWorkers || []).map((worker) => ({
          value: worker.id,

          label: `${worker.first_name} ${worker.last_name}`,
        }));

        setAssignedWorkers(initialWorkers);

        reset({
          ...appointment,

          date: moment(appointment.date).format("YYYY-MM-DD"),
        });
      }
    }
  }, [isOpen, appointment, isUpdate, reset, clearErrors]);


  useEffect(()=>{

    if(appointment && appointment !== undefined){
      const preparedData = appointment?.assigned_workers?.map((worker)=>({label:`${worker?.first_name} ${worker?.last_name}`,value:worker?.id}))
   
      setAssignedWorkers(preparedData)
    }
  },[])
  const onSubmit = (data) => {
    console.log({data})
    if (isUserGracePeriodOver) {
      return;
    }
   
    const assignedWorkerIds = assignedWorkers.map((worker) => worker.value);

    const payload = {
      ...data,
      pt_id,
      assigned_workers: assignedWorkerIds,
      notify_with_mail: enabled,
    };
    setCreateLoader(LOADER_STATUS.PENDING);
    if (isUpdate && updateAppointment) {
      updateAppointment({ ...payload, appointment_id: appointment.id });
    } else {
      dispatch(participantAppointmentCreateCall(payload));
    }
  };

  useEffect(() => {
    if (!isLoading && createLoader === LOADER_STATUS.PENDING) {
      setCreateLoader(LOADER_STATUS.COMPLATE);
      reset();
      setAssignedWorkers([]);
      clearErrors("date");
      clearErrors("time");
      clearErrors("location");
      clearErrors("notes");
      clearErrors("comment");
      clearErrors("status");
      clearErrors("title");
      clearErrors("assignedWorkers");
      closeModal();
    }
  }, [isLoading]);
  const isCompleted = appointment?.status === "Completed";

  const modalFooter = () => (
    <CustomButton
      isDisabled={isLoading || isUserGracePeriodOver || isCompleted}
      showLoading={isLoading}
      label={isUpdate ? "Update" : "Submit"}
      clickHandler={() => {
        if (isUserGracePeriodOver) {
          return;
        }
        const buttonRef = document.getElementById("appointmentButtonModal");
        if (buttonRef) {
          buttonRef.click();
        }
      }}
    />
  );

  return (
    <ModalSkeleton
      isOpen={isOpen}
      closeModal={closeModal}
      modalFooter={modalFooter()}
      cssClass='w-[50%]'
      title={`${isUpdate ? "Update " : "Book "}an Appointment`}
    >
      <div className='flex flex-col'>
        <p className='text-sm text-gray-500 mb-2'>
          {`Please enter the following details to ${
            isUpdate ? "update " : "book "
          }an appointment`}
        </p>

        <form onSubmit={handleSubmit(onSubmit)} className='space-y-3'>
          <div>
            <label
              htmlFor='title'
              className='block text-sm font-medium text-gray-700'
            >
              Title
            </label>
            <div className='mt-1'>
              <input
                {...register("title", {
                  required: true,
                })}
                className='did-floating-input'
                type='text'
              />
              {errors?.title?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required!
                </span>
              )}
            </div>
          </div>
          <div>
            <label
              htmlFor='date'
              className='block text-sm font-medium text-gray-700'
            >
              Date
            </label>
            <div className='mt-1'>
              <input
                {...register("date", {
                  required: true,
                  value: watchFormData.date,
                })}
                type='date'
                name='date'
                id='date'
                min={moment().format("DD-MM-YYYY")}
                className='focus:bg-[#4b6bb710] px-4 py-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
              />
              {errors?.date?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required!
                </span>
              )}
            </div>
          </div>

          <div>
            <label
              htmlFor='time'
              className='block text-sm font-medium text-gray-700'
            >
              Time
            </label>
            <div className='mt-1'>
              <input
                {...register("time", {
                  required: true,
                })}
                type='time'
                name='time'
                id='time'
                // min={moment().format("HH:MM")}
                className='focus:bg-[#4b6bb710] px-4 py-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
              />
              {errors?.time?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required!
                </span>
              )}
            </div>
          </div>
          <div>
            <label
              htmlFor='address'
              className='block text-sm font-medium text-gray-700'
            >
              Address
            </label>
            <div className='mt-1'>
              <input
                {...register("location", {
                  required: true,
                  maxLength: 254,
                })}
                type='text'
                name='location'
                id='location'
                className='focus:bg-[#4b6bb710] px-4 py-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
              />
              {errors?.location?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required!
                </span>
              )}
            </div>
          </div>
          <div>
            <label
              htmlFor='notes'
              className='block text-sm font-medium text-gray-700'
            >
              Appointment Notes
            </label>
            <div className='mt-1'>
              <textarea
                {...register("notes", {
                  required: true,
                })}
                rows={3}
                name='notes'
                id='notes'
                className='focus:bg-[#4b6bb710] overflow-y-auto border px-4 py-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
              />
              {errors?.notes?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required!
                </span>
              )}
            </div>
          </div>
          <div>
            <label
              htmlFor='assignedWorkers'
              className='block text-sm font-medium text-gray-700'
            >
              Assigned Workers
            </label>
            <div className='mt-1'>
              <SelectOption
                isMulti={true}
                data={participant_assigned_workers}
                selectedOption={assignedWorkers}
                handleChange={setAssignedWorkers}
              />
            </div>
          </div>
          {isUpdate && (
            <div>
              <label
                htmlFor='comment'
                className='block text-sm font-medium text-gray-700'
              >
                Comments
              </label>
              <div className='mt-1'>
                <textarea
                  {...register("comment", {})}
                  rows={3}
                  name='comment'
                  id='comment'
                  className='focus:bg-[#4b6bb710] overflow-y-auto border px-4 py-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
                />
                {/* {errors?.notes?.type === "required" && (
        <span className='text-sm text-red-500'>
          This field is required!
        </span>
      )} */}
              </div>
            </div>
          )}

          <div className='flex gap-x-4'>
            <SwitchInput
              handleChange={setEnabled}
              checked={enabled}
              label='Send Email Notification'
              subLabel='send email to all the workers'
            />

            {isUpdate && (
              <div className='flex flex-col'>
                <label
                  htmlFor='status'
                  className='block text-sm font-medium text-gray-700'
                >
                  Status
                </label>
                <select
                  {...register("status", {
                    required: true,
                  })}
                  id='status'
                  className='mt-1 focus:bg-[#4b6bb710] border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
                >
                  <option value=''>Select Status</option>
                  <option value='Rescheduled'>Rescheduled</option>
                  <option value='Completed'>Completed</option>
                  <option value='Canceled'>Canceled</option>
                </select>
                {errors?.status?.type === "required" && (
                  <span className='text-sm text-red-500'>
                    This field is required!
                  </span>
                )}
              </div>
            )}
          </div>
          <button
            type='submit'
            className='hidden sr-only'
            id='appointmentButtonModal'
          />
        </form>
      </div>
    </ModalSkeleton>
  );
};

export default Appointment;
