import React, { useContext, useEffect, useMemo } from "react";
import SideBar from "../../../../components/common/SideBar/SideBarNew";
import { LoginContext } from "../../../../helpers/LoginContext";
import { useNavigate } from "react-router";
import { RiTeamLine } from "react-icons/ri";
import { FiUsers } from "react-icons/fi";
// import { HiOutlineDocumentDuplicate } from "react-icons/hi";
import { USER_ROLE } from "../../../../Constants/constant";
import { useState } from "react";
import { apiGetRequest } from "../../../../helpers/Requests";
import Loader from "../../../../components/common/Loader/Loader";
import Header from "../../../../components/common/Header/Header";
import { roleAccess } from "../../../../helpers/RolesPermission";
import { classNames } from "../../../../Utils/utils";
import WelcomeBanner from "pages/SupportCoordination/Participant/Dashboard/partials/dashboard/WelcomeBanner";
import { PERMISSION_KEYS, PERMISSION_KEYS_CHILDREN } from "store/constants";

const HRMDashboard = () => {
  const { sideBarPadding } = useContext(LoginContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [stats, setStats] = useState(null);

  const cards = [
    {
      name: "View All Applicants",
      role: "All applicant in the hiring pipeline",
      href: "hrm/all-applicants",
      icon: FiUsers,
      bgColor: "#8C198F",
      userRole: [USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager],
      for: "applicant",
      permissionChild: PERMISSION_KEYS_CHILDREN.HRM.APPLICANT.SHOW,
    },
    // {
    //   name: "Add New Applicant",
    //   role: "Submit a new applicant to the pipeline",
    //   href: "add-applicant",
    //   icon: AiOutlineUserAdd,
    //   bgColor: "#00D1B7",
    //   userRole: [USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager],
    //   for: "applicant",
    // },
    {
      name: "All Workers",
      role: "Manage workers on the system",
      href: "hrm/team-management",
      icon: RiTeamLine,
      bgColor: "#FF456E",
      userRole: [USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager],
      for: "team",
      permissionChild: PERMISSION_KEYS_CHILDREN.HRM.WORKER.SHOW,
    },
    {
      name: "View All Managers",
      role: "view all managers",
      href: "hrm/all-managers",
      icon: FiUsers,
      bgColor: "#8C198F",
      userRole: [USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager],
      for: "team",
      permissionChild: PERMISSION_KEYS_CHILDREN.HRM.MANAGER.SHOW,
    },
    // {
    //   name: "Add Manager",
    //   role: "Create new manager",
    //   href: "add-manager",
    //   icon: AiOutlineUserAdd,
    //   bgColor: "#00D1B7",
    //   userRole: [USER_ROLE.Sp_Admin],
    //   for: "team",
    // },
    // {
    //   name: "View All Policies",
    //   role: "View all organization policies",
    //   href: "policies",
    //   icon: HiOutlineDocumentDuplicate,
    //   bgColor: "#FFCC00",
    //   userRole: [USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager],
    //   for: "misc",
    // },
  ];

  useEffect(() => getHrmDashboard(), []);

  const getHrmDashboard = async () => {
    try {
      setIsLoading(true);
      const res = await apiGetRequest("sp/workers-and-managers-stats");

      if (res.status === 200) {
        setStats(res.data ? res.data.stats : []);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("err", err);
    }
  };

  const getLink = (value) => {
    if (value === "applicants") return "hrm/all-applicants";
    else if (value === "workers") return "hrm/team-management";
    else if (value === "managers") return "hrm/all-managers";
    else return "#";
  };

  const statsToMap = useMemo(() => {
    if (stats === null) return [];

    const hrmStats = Object.keys(stats)
      .map((x, index) => {
        const obj = {};

        // if (x === "managers" && roleAccess([USER_ROLE.Sp_manager])) {
        //   return null;
        // }
        Object.assign(obj, {
          name: `All ${x}`,
          href: getLink(x),
          icon: FiUsers,
          value: stats[x],
        });
        return obj;
      })
      .filter((x) => x !== null);

    return hrmStats ? hrmStats : [];
  }, [stats]);

  return (
    <>
      <div className='min-full'>
        <SideBar classes='bg-gradient' />
        <div
          className={`${
            sideBarPadding
              ? "lg:pl-[4rem] duration-300"
              : "lg:pl-64 duration-300"
          } flex flex-col fullScreenHeight`}
        >
          <main className='flex-1'>
            <Header title='Human Resources System' />
            <div className='min-full'>
              <div className='max-w-7xl mx-auto sm:px-6 lg:px-8 space-y-6 py-8'>
                <WelcomeBanner />

                <div
                  className={`grid  gap-5 ${
                    // roleAccess([USER_ROLE.Sp_manager])
                    //   ? "grid-cols-2 sm:grid-cols-1 lg:grid-cols-2 capitalize"
                    //   :
                    "grid-cols-3 sm:grid-cols-1 lg:grid-cols-3 capitalize"
                  }`}
                >
                  {isLoading ? (
                    <div className="flex justify-center min-w-full">
                    <Loader cssClass='text-black ' isSmallView />
                    </div>
                  ) : (
                    statsToMap &&
                    statsToMap.length > 0 &&
                    statsToMap
                      // .filter((x) =>
                      //   roleAccess(
                      //     [],
                      //     PERMISSION_KEYS.HRM,
                      //     x?.permissionChild || null
                      //   )
                      // )
                      .map((card) => (
                        <div
                          key={card.name}
                          // onClick={() => {
                          //   if (card.href) navigate(`/${card.href}`);
                          // }}
                          className='ring-opacity-5 ring-black ring-1 hover:shadow-md overflow-hidden rounded-lg bg-white shadow'
                        >
                          <div className='p-5'>
                            <div className='flex items-center'>
                              <div className='flex-shrink-0'>
                                <card.icon
                                  className='h-6 w-6 text-gray-400'
                                  aria-hidden='true'
                                />
                              </div>
                              <div className='ml-5 w-0 flex-1'>
                               
                                <dl>
                                  <dt className='truncate text-sm font-bold text-gray-500'>
                                  {card.name === "All managers" ? "All Primary Portal Users" : card.name === "All workers" ? "All Staff" : card.name}
                                  </dt>
                                  <dd>
                                    <div className='text-lg font-medium text-gray-900'>
                                      {card.value}
                                    </div>
                                  </dd>
                                </dl>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                  )}
                </div>

                <div
                  className={`grid gap-3 p-6 bg-gray-50 md:rounded-lg  ${
                    roleAccess([USER_ROLE.Sp_manager])
                      ? "grid-cols-3"
                      : "lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-4"
                  }`}
                >
                  {/* <h2 className="text-sm font-bold text-gray-500">
                      Applicant Manangement
                    </h2> */}
                  {cards
                    .filter((x) =>
                      roleAccess(
                        [],
                        PERMISSION_KEYS.HRM,
                        x?.permissionChild || null
                      )
                    )
                    .filter((x) => roleAccess(x.userRole))
                    .map((card, index) => (
                      <div
                        onClick={() => {
                          if (card.href) navigate(`/${card.href}`);
                        }}
                        role='button'
                        tabIndex={0}
                        key={index}
                        className='cursor-pointer relative rounded-lg border border-gray-300 bg-white p-1 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 capitalize'
                      >
                        <div
                          className={classNames(
                            "flex-shrink-0 flex items-center justify-center w-16 h-12 py-8 text-white text-sm font-medium rounded-md"
                          )}
                          style={{ backgroundColor: `${card.bgColor}` }}
                        >
                          <card.icon
                            className={classNames(
                              "flex-shrink-0 h-5 w-5 text-white"
                            )}
                            aria-hidden='true'
                          />
                        </div>
                        <div className='flex-1 min-w-0'>
                          <div className='focus:outline-none'>
                            <span
                              className='absolute inset-0'
                              aria-hidden='true'
                            />
                            <p className='text-sm font-medium text-gray-900 mt-3'>
                          
                              {/* {card.name} */}
                              {card.name === "View All Managers" ? "Primary Portal Users" : card.name === "All Workers" ? "View All Staff" : card.name}

                            </p>
                            <p className='text-sm text-gray-500 truncate mb-3'>
                              {card.role === "view all managers" ? "View All Primary Portal Users" : card.role === "Manage workers on the system" ? "Manage staff on the system" : card.role}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
                {/* <div className="grid grid-cols-1 gap-3 mt-3">
                    <h2 className="text-sm font-bold text-gray-500">
                      Team Manangement
                    </h2>
                    {cards
                      .filter(
                        (x) =>
                          x.userRole.find((y) => y === userType) &&
                          x.for === "team"
                      )
                      .map((card, index) => (
                        <div
                          onClick={() => {
                            if (card.href) navigate(`/${card.href}`);
                          }}
                          role="button"
                          tabIndex={0}
                          key={index}
                          className="cursor-pointer relative rounded-lg border border-gray-300 bg-white p-1 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                        >
                          <div
                            className={classNames(
                              "flex-shrink-0 flex items-center justify-center w-16 h-12 py-8 text-white text-sm font-medium rounded-md"
                            )}
                            style={{ backgroundColor: `${card.bgColor}` }}
                          >
                            <card.icon
                              className={classNames(
                                "flex-shrink-0 h-5 w-5 text-white"
                              )}
                              aria-hidden="true"
                            />
                          </div>
                          <div className="flex-1 min-w-0">
                            <div className="focus:outline-none">
                              <span
                                className="absolute inset-0"
                                aria-hidden="true"
                              />
                              <p className="text-sm font-medium text-gray-900 mt-3">
                                {card.name}
                              </p>
                              <p className="text-sm text-gray-500 truncate mb-3">
                                {card.role}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className="grid grid-cols-1 gap-3 mt-3">
                    <h2 className="text-sm font-bold text-gray-500">Misc</h2>
                    {cards
                      .filter(
                        (x) =>
                          x.userRole.find((y) => y === userType) &&
                          x.for === "misc"
                      )
                      .map((card, index) => (
                        <div
                          onClick={() => {
                            if (card.href) navigate(`/${card.href}`);
                          }}
                          role="button"
                          tabIndex={0}
                          key={index}
                          className="cursor-pointer relative rounded-lg border border-gray-300 bg-white p-1 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                        >
                          <div
                            className={classNames(
                              "flex-shrink-0 flex items-center justify-center w-16 h-12 py-8 text-white text-sm font-medium rounded-md"
                            )}
                            style={{ backgroundColor: `${card.bgColor}` }}
                          >
                            <card.icon
                              className={classNames(
                                "flex-shrink-0 h-5 w-5 text-white"
                              )}
                              aria-hidden="true"
                            />
                          </div>
                          <div className="flex-1 min-w-0">
                            <div className="focus:outline-none">
                              <span
                                className="absolute inset-0"
                                aria-hidden="true"
                              />
                              <p className="text-sm font-medium text-gray-900 mt-3">
                                {card.name}
                              </p>
                              <p className="text-sm text-gray-500 truncate mb-3">
                                {card.role}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div> */}
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default HRMDashboard;
