import React from "react";

const Card = ({ title, value, icon, text, bgColor }) => {
  return (
    <div
    className={`bg-white border-t shadow-md p-5 rounded-lg  h-full flex flex-col`}
    style={{ backgroundColor: bgColor ? bgColor : "" }}
  >
    <div className="flex justify-end">
      <img src={icon} alt={title} width={50} height={40} />
    </div>
    <div className="flex flex-col flex-grow justify-between">
      <p className={`text-2xl font-bold ${bgColor ? 'text-white' : 'text-[#363636]'} pb-1`}>{value}</p>
      <div className="flex gap-x-2 items-center">
        <p className={`text-md ${bgColor ? 'text-white' : 'text-[#525252]'} `}>{title}</p>
        <span className={`text-xs ${bgColor ? 'text-white' : 'text-gray-400'}`}>{text}</span>
      </div>
    </div>
  </div>
  
  
  );
};

export default Card;
