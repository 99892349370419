import React, { useEffect, useState } from "react";
import ActivityStat from "../../../Pages/ServiceProvider/ActivityStat/ActivityStat";
import FormsList from "../../../Pages/ServiceProvider/FormsList/FormsList";
import ProfileDetails from "../../../Pages/ServiceProvider/ProfileDetails/ProfileDetails";
import CircularLoader from "../../../common/CircularLoader/CircularLoader";
import SubmittedFormView from "../../SubmittedFormsView/index";
import "./ParticipantProfileTabs.css";
import Appointments from "../Appointments/index";
import ShiftNotes from "../ShiftNotes/index"
import Settings from "../Setting/index";
import {
  PARENT_FORM_CATEGORY_SLUG,
  PARTICIPANT_PROFILE_TABS,
} from "../../../../Constants/constant";
import ParticipantProfileTabItem from "./ParticipantProfileTabItem";
import RiskManagementTab from "./RiskManagementTab";
import Documents from "../../../../pages/HRM/Pages/Worker/Documents/index";
import { parseBooleanVal, selectedTabState } from "../../../../Utils/utils";
import CaseNotesTab from "../CaseNotes";
import { useMemo } from "react";
import _ from "lodash";
import FundingSetup from "../../../../pages/SupportCoordination/Funding/FundingSetup/index";
import { roleAccess } from "../../../../helpers/RolesPermission";
import { PERMISSION_KEYS, PERMISSION_KEYS_CHILDREN } from "store/constants";
// import Documents from "../Documents/index";
// import WorkerSetting from "../../../../pages/HRM/Pages/Worker/Documents/index";

const ParticipantProfileTabs = ({ ...props }) => {
  const {
    getFolderListForHRMLoading,
    FolderListForHRM,
    FileListForHRM,
    participantInfo,
    isCaseNoteCall,
    resetCaseNoteCall,
    toggleState,
    setToggleState,
    refreshNavigations,
    // currentUser,
  } = props;

  const [tabs] = useState(PARTICIPANT_PROFILE_TABS);

  const [selectedTab] = useState(
    selectedTabState.get()
      ? selectedTabState.get()
      : PARTICIPANT_PROFILE_TABS[0]
  );

  const toggleTab = (tab) => {
    setToggleState(tab);
    selectedTabState.save(JSON.stringify(tab));
  };

  useEffect(() => {
    if (toggleState.id !== selectedTab.id) setToggleState(selectedTab);
  }, [selectedTab]);

  const loadTabComponent = () => {
    switch (toggleState.id) {
      case 1:
        return (
          <ProfileDetails
            participantInfo={participantInfo}
            setToggleState={setToggleState}
          />
        );
      case 2:
        return (
          <>
            <ActivityStat />
            <FormsList background='bg-gradient' textColor='text-gray-50' />
            <FormsList background='bg-white' textColor='text-gray-900' />
          </>
        );
      // case 3:
      //   return (
      //     <>
      //       {props && props.participantInfo && props.participantInfo.uuid ? (
      //         // <SupportPlan
      //         //   supportPlansId={"a73c7862-5fdf-47d3-981e-01fb1342e0ee"}
      //         //   participantsUid={props.participantInfo.uuid}
      //         //   title="Support Plan"
      //         // />
      //         <SupportPlanViewProfile
      //           participantInfo={participantInfo}
      //           settingsData={settingsData}
      //         />
      //       ) : null}
      //     </>
      //   );
      case 5:
        return (
          <>
            {participantInfo && participantInfo.uuid ? (
              <SubmittedFormView
                parentSlug={PARENT_FORM_CATEGORY_SLUG.MEDICATION.SLUG}
                participantId={participantInfo.id}
                supportPlansId={PARENT_FORM_CATEGORY_SLUG.MEDICATION.UUID}
                participantsUid={props.participantInfo.uuid}
                title='Medication'
              />
            ) : (
              <CircularLoader
                classes='flex justify-center items-center'
                classloader='loader-l'
              />
            )}
          </>
        );
      case 6:
        return (
          <>
            {participantInfo.uuid && (
              <SubmittedFormView
                parentSlug={
                  PARENT_FORM_CATEGORY_SLUG.PARTICIPANT_ASSESSMENT.SLUG
                }
                participantId={participantInfo.id}
                supportPlansId={
                  PARENT_FORM_CATEGORY_SLUG.PARTICIPANT_ASSESSMENT.UUID
                }
                participantsUid={props.participantInfo.uuid}
                title='Participant Assessment'
              />
            )}
          </>
        );
      case 8:
        return (
          <>
            {participantInfo.uuid && (
              <SubmittedFormView
                participantId={participantInfo.id}
                supportPlansId={PARENT_FORM_CATEGORY_SLUG.HIGH_CARE.UUID}
                participantsUid={props.participantInfo.uuid}
                title='High Care'
                parentSlug={PARENT_FORM_CATEGORY_SLUG.HIGH_CARE.SLUG}
              />
            )}
          </>
        );
      case 9:
        return (
          <>
            {participantInfo.uuid && (
              <SubmittedFormView
                participantId={participantInfo.id}
                supportPlansId={
                  PARENT_FORM_CATEGORY_SLUG.INJURY_MANAGEMENT.UUID
                }
                participantsUid={props.participantInfo.uuid}
                title='Incident/Injury Management'
                parentSlug={PARENT_FORM_CATEGORY_SLUG.INJURY_MANAGEMENT.SLUG}
              />
            )}
          </>
        );
      case 10:
        return (
          roleAccess(
            [],
            PERMISSION_KEYS.PARTICIPANT,
            PERMISSION_KEYS_CHILDREN.PARTICIPANT.DOCUMEMT.SHOW
          ) && (
            <div>
              <Documents
                getFolderListForHRMLoading={getFolderListForHRMLoading}
                requiredDocuments={true}
                FolderListForHRM={FolderListForHRM}
                FileListForHRM={FileListForHRM}
                role={"participant"}
                worker={participantInfo}
              />
            </div>
          )
        );
      case 11:
        return (
          <RiskManagementTab
            participantId={participantInfo.id}
            participantInfoUUID={participantInfo.uuid || ""}
            parentSlug={PARENT_FORM_CATEGORY_SLUG.RISK_MANAGEMENT.SLUG}
          />
        );
      case 12:
        return (
          <Appointments type='page_render' participantInfo={participantInfo} />
        );
      case 13:
        return (
          roleAccess(
            [],
            PERMISSION_KEYS.PARTICIPANT,
            PERMISSION_KEYS_CHILDREN.PARTICIPANT.SETTING.SHOW
          ) && <Settings />
        );
      case 14:
        return (
          <>
            {participantInfo && participantInfo.uuid ? (
              <SubmittedFormView
                supportPlansId={
                  PARENT_FORM_CATEGORY_SLUG.PERSON_CENTERED_PLAN.UUID
                }
                participantsUid={props.participantInfo.uuid}
                title='Person Centred Plan'
                parentSlug={PARENT_FORM_CATEGORY_SLUG.PERSON_CENTERED_PLAN.SLUG}
              />
            ) : (
              <CircularLoader
                classes='flex justify-center items-center'
                classloader='loader-l'
              />
            )}
          </>
        );
      case 15:
        return (
          <>
            {participantInfo.uuid && (
              <SubmittedFormView
                supportPlansId={PARENT_FORM_CATEGORY_SLUG.SUPPORT_PLAN.UUID}
                participantsUid={props.participantInfo.uuid}
                title='Support Plan'
                participantId={participantInfo.id}
                parentSlug={PARENT_FORM_CATEGORY_SLUG.SUPPORT_PLAN.SLUG}
              />
            )}
          </>
        );
      case 16:
        return (
          <>
            {participantInfo.uuid && (
              <CaseNotesTab
                participantInfo={participantInfo}
                isCaseNoteCall={isCaseNoteCall}
                resetCaseNoteCall={resetCaseNoteCall}
                setToggleState={setToggleState}
              />
            )}
          </>
        );
      case 17:
        return (
          <>
            {roleAccess(
              [],
              PERMISSION_KEYS.PARTICIPANT,
              PERMISSION_KEYS_CHILDREN.PARTICIPANT.FUNDING.SHOW
            ) &&
              participantInfo &&
              participantInfo.support_coordination && (
                <FundingSetup type='tab' setToggleState={setToggleState} />
              )}
          </>
        );
      case 18:
        return (
          <>
            {participantInfo.uuid && (
              <SubmittedFormView
                supportPlansId={PARENT_FORM_CATEGORY_SLUG.Exit_Transaction.UUID}
                participantsUid={props.participantInfo.uuid}
                title='Exit/Transition'
                participantId={participantInfo.id}
                parentSlug={PARENT_FORM_CATEGORY_SLUG.Exit_Transaction.SLUG}
              />
            )}
          </>
        );
      case 19:
        return(
            <>
              <ShiftNotes participantInfo={participantInfo} />
            </>
        );

      default:
        return <></>;
    }
  };
  const tabsToMap = useMemo(() => {
    const filterTabs = (tab) => {
      const permissions = tab.permissions;
      return (
        !permissions ||
        roleAccess([], PERMISSION_KEYS.PARTICIPANT, permissions.SHOW)
      );
    };
    const filterChildTabs = (childTab) => {
      const permissions = childTab.permissions;
      return (
        !permissions || roleAccess([], PERMISSION_KEYS.PARTICIPANT, permissions)
      );
    };
    const filteredTabs = tabs.filter(filterTabs);
    filteredTabs.forEach((tab) => {
      const childs = tab.children;
      if (childs && childs.length > 0) {
        tab.children = childs.filter(filterChildTabs);
      }
    });
    const fundingIndex = filteredTabs.findIndex(
      (x) =>
        x.id === 17 &&
        !parseBooleanVal(participantInfo?.support_coordination || false)
    );
    const finalTabs =
      fundingIndex !== -1
        ? filteredTabs.filter(
            (x) => x.id !== 17 && roleAccess([...x.roleAccess])
          )
        : filteredTabs.filter((x) => roleAccess([...x.roleAccess]));

    return finalTabs;
  }, [tabs, participantInfo, refreshNavigations]);

  return (
    <div className='bg-white shadow-lg rounded-lg'>
      <div className='print:hidden sticky z-[8] top-[79px]'>
        <div className='bloc-tabs bg-gradient py-4 rounded-t-lg text-white '>
          {tabsToMap.map((tab, index) => (
            <ParticipantProfileTabItem
              tabItem={tab}
              key={index}
              toggleTab={toggleTab}
              selectedTab={toggleState.id}
            />
          ))}
        </div>
      </div>
      <div className='content-tabs'>
        <div className={"content active-content"}>{loadTabComponent()}</div>
      </div>
    </div>
  );
};

export default ParticipantProfileTabs;
