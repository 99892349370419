import React, { useState, useContext } from "react";
import SideBar from "../../components/common/SideBar/SideBarNew";
import SPDashboardUpdated from "../ServiceProvider/SPDashboardUpdated";
import Employee from "../EmployeePortal/Pages";
import { LoginContext } from "../../helpers/LoginContext";
import { USER_ROLE } from "../../Constants/constant";
import { useNavigate } from "react-router";
import Header from "../../components/common/Header/Header";
import SuperAdminDashboard from "../SuperAdmin/SuperAdminDashboard/SuperAdminDashboard";
import { useSelector } from "react-redux";
import { getUser } from "../../store/selector/auth.selector";
import { getUserTypeFromLC } from "../../Utils/utils";

const Dashboard = () => {
  const [userType] = useState(getUserTypeFromLC()); // react hook
  const currentUser = useSelector(getUser); // redux hook
  const { sideBarPadding } = useContext(LoginContext); // react hook

  const navigate = useNavigate();

  // redirect user to login page if anyone not authorise
  if (!userType || userType === undefined) {
    navigate("/");
  }

  const loadContent = () => {
    if (currentUser && userType) {
      switch (userType) {
        case USER_ROLE.Super_Admin:
          return <SuperAdminDashboard />;
        case USER_ROLE.Sp_Admin:
          return <SPDashboardUpdated />;
        case USER_ROLE.Sp_manager:
          return <SPDashboardUpdated />;
        case USER_ROLE.Sp_Standard_User:
          return <Employee />;
        default:
          return;
      }
    }
    return <></>; // if missing User, returns an empty fragment
  };

  return (
    <div className='min-h-full'>
      <SideBar />
      <div
        className={`${
          sideBarPadding
            ? "lg:pl-[4rem] duration-300 flex flex-col"
            : "lg:pl-64 duration-300 flex flex-col"
        }`}
      >
        <main className='flex-1 relative'>
          <Header title='Dashboard' />
          <div>{loadContent()}</div>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
