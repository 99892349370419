import React from "react";
import Stars from "../Stars/Stars";
import logo from "../../../assets/V360_logo.svg";
import DOMPurify from "dompurify";
const LoginScreenSection = ({heading,userImg,name,desc,slugData}) => {
  return (
    <div className="hidden lg:block relative w-1/2 bg-gradient order-2 rounded-tl-3xl">
      <div className="flex justify-end">
        {/*<p className="p-6 items-center">*/}
        {/*    Vertex 360 UI logo*/}
        {/*</p>*/}
      <div className="flex justify-end pr-5">
        <img className="logo h-8 w-auto" src={logo} alt="bearplex" />
      </div>
      </div>
      {
        slugData?.branding_text ?   <div>
        <p
          className="
              text-center text-white
              font-medium
              text-3xl
              w-5/6
              mx-auto
              pt-24
            "
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(slugData?.branding_text || "", {
                ALLOWED_ATTR: ['target', 'href', 'rel'],
              }),
            }}
       / >
  
      
      </div> :
        <>
        <div>
         <p
           className="
               text-center text-white
               font-medium
               text-3xl
               w-5/6
               mx-auto
               pt-24
             "
         >
           {heading}
         </p>
       </div>
 
       <div className="flex justify-center mt-8 mb-4">
         <div className="flex-shrink-0 h-16 w-16 relative">
           <img
             className="h-16 w-16 rounded-full"
             src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60"
             alt=""
           />
         </div>
       </div>
 
       <div className="text-center">
         <p className="font-medium text-base text-white">{name}</p>
         <p className="font-medium text-sm text-white">
           {desc}
         </p>
       </div>
 
       <div>
         <p className="flex justify-center pt-4">
           <Stars />
         </p>
       </div>
       </>
      }
     
     

      <div className="flex justify-center">
  <p className="font-normal text-white text-sm p-6 absolute bottom-0">
    © Vertex360 {new Date().getFullYear()}
  </p>
</div>
    </div>
  );
};

export default LoginScreenSection;
