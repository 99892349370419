import React from "react";
import { PARENT_FORM_CATEGORY_SLUG, USER_ROLE } from "Constants/constant";
import CategoryFormsListing from "components/Modals/CategoryFormsListing/CategoryFormsListing";
import NotificationDropDown from "components/common/NotificationDropDown/NotificationDropDown";
import { capitalizeFirstLetter } from "Utils/utils";
import SilRooms from "../sections/SilRooms";
// import FundingSetup from "pages/SupportCoordination/Funding/FundingSetup/index";
// import Appointment from "components/ServiceProvider/Participant/Appointments/index";
// import ParticipantImageToolTip from "components/common/Tooltip/ParticipantImageToolTip";
// import CustomButton from "components/common/Button/CustomButton";
// import {
//   BLK_PORTAL_PREMISSION_KEYS_CHILDREN,
//   PERMISSION_KEYS,
//   PERMISSION_KEYS_CHILDREN,
// } from "store/constants";
// import { roleAccess } from "helpers/RolesPermission";
// import { BiPlus, BiPrinter } from "react-icons/bi";

const SilHomeProfileHeader = (props) => {
  const { selectedTab, selectedAccommodationInfo } = props;

  const loadHeaderButton = () => {
    switch (selectedTab) {
      case "form-property-management":
        return (
          <CategoryFormsListing
            participantId={selectedAccommodationInfo.id}
            buttonText='Property Management'
            slug={PARENT_FORM_CATEGORY_SLUG.PropertyManagement.SLUG}
            particpantUuid={selectedAccommodationInfo.uuid}
            role='sil'
          />
        );
      case "form-participant-enrollment":
        return (
          <CategoryFormsListing
            participantId={selectedAccommodationInfo.id}
            buttonText='Participant Enrolment'
            slug={PARENT_FORM_CATEGORY_SLUG.PropertyEnrollment.SLUG}
            particpantUuid={selectedAccommodationInfo.uuid}
            role='sil'
          />
        );
      case "form-others":
        return (
          <CategoryFormsListing
            role='sil'
            participantId={selectedAccommodationInfo.id}
            buttonText='Others'
            slug={PARENT_FORM_CATEGORY_SLUG.SilFromsOthers.SLUG}
            particpantUuid={selectedAccommodationInfo.uuid}
          />
        );
      case "form-property-notes":
        return (
          <CategoryFormsListing
            participantId={selectedAccommodationInfo.id}
            buttonText='Property Notes'
            slug={PARENT_FORM_CATEGORY_SLUG.HIGH_CARE.SLUG}
            particpantUuid={selectedAccommodationInfo.uuid}
            role='sil'
          />
        );
      case "sil-room":
        return (
          <SilRooms
            isCallFromHeader={true}
            selectedAccommodationInfo={selectedAccommodationInfo}
          />
        );
      default:
        <></>;
    }
  };

  return (
    <>
      <div className='px-6 flex justify-between items-center bg-white h-[80px] border-b border-gray-200'>
        <div className='mt-5 mb-5 flex items-center space-x-4'>
          <p className='capitalize text-gray-900 text-xl font-semibold'>
            {`${capitalizeFirstLetter(
              selectedAccommodationInfo?.street_address
            )}, ${capitalizeFirstLetter(selectedAccommodationInfo?.suburb)}, ${
              selectedAccommodationInfo?.stateObj?.name?.toUpperCase() || ""
            } ${selectedAccommodationInfo?.postal_code}`}
          </p>
        </div>
        <div className='flex md:space-x-4 items-center text-sm print:hidden'>
          <div>{loadHeaderButton()}</div>
          <div className='flex sm:mt-0 sm:ml-4'>
            <NotificationDropDown />
          </div>
        </div>
      </div>
    </>
  );
};

export default SilHomeProfileHeader;
