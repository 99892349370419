import React, { useState, useEffect } from "react";
import TableHeader from "components/common/Table/TableHeader";
import Search from "components/common/Search/Search";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import Pagination from "components/common/Pagination";
import {
  LOADER_STATUS,
  SP_PARTICIPANTS_LIST_LIMIT,
  SORT_TYPE,
  StatusOptions,
} from "Constants/constant";
import SilAccommodationTableItem from "./SilAccommodationTableItem";
import SelectOption from "components/common/Select/Select";

const SilAccommodationTable = (props) => {
  const {
    silAccommodations,
    isLoading,
    queryParams,
    handleFetch,
    renderLoading,
    paginationObj,
    setPaginationObj,
    setOpenModal,
  } = props;

  const [search, setSearch] = useState(null);
  const [sortBy, setSortBy] = useState(queryParams.sort_field);
  const [sortDescending, setSortDescending] = useState(
    queryParams.sort_order === SORT_TYPE.DESC ? true : false
  );
  const [selectedStatus, setSelectedStatus] = useState(StatusOptions[1]);

  useEffect(() => {
    setSortBy(queryParams.sort_field ? queryParams.sort_field : null);
    if (queryParams.sort_order) {
      setSortDescending(
        queryParams.sort_order
          ? queryParams.sort_order === SORT_TYPE.DESC
            ? true
            : false
          : false
      );
    }
    if (queryParams.hasOwnProperty("filter")) {
      if (queryParams.filter !== null) {
        setSelectedStatus(
          queryParams.filter.status && queryParams.filter.status.length > 0
            ? StatusOptions.find(
                (x) => x.value === queryParams.filter.status[0]
              )
            : null
        );
      }
    }
    if (queryParams.search) {
      setSearch(queryParams.search);
    }
  }, [queryParams]);

  useEffect(() => {
    if (search === null) return;
    const timeOutId = setTimeout(
      () =>
        handleFetch(
          {
            search: search,
          },
          sortBy
        ),
      1000
    );
    return () => clearTimeout(timeOutId);
  }, [search]);

  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(sortDescending);
    handleFetch(
      {
        sort_field: by,
        sort_order: !sortDescending ? SORT_TYPE.DESC : SORT_TYPE.ASC,
        page: 1,
      },
      sortBy
    );
  };

  useEffect(() => {
    if (
      renderLoading !== LOADER_STATUS.COMPLATE ||
      silAccommodations?.length !== 0
    ) {
      handleFetch({}, sortBy);
    }
  }, []);

  return (
    <>
      <div className='max-w-7xl mx-auto'>
        <div
          style={{ paddingTop: "15px", top: "70px" }}
          className='sm:px-6 lg:px-8 flex flex-col sm:flex-row justify-between items-center sticky py-2 bg-white z-[6]'
        >
          <div className='w-full sm:w-1/3 md:w-2/12 lg:w-2/12 mb-3 sm:mb-0'>
            <SelectOption
              isDisabled={isLoading}
              isSearch={false}
              selectedOption={selectedStatus}
              handleChange={(e) => {
                setSelectedStatus(e);
                const payload = { status: e.value, page: 1 };
                if (search !== null) {
                  Object.assign(payload, {
                    search,
                  });
                }
                handleFetch(payload, sortBy);
              }}
              data={StatusOptions}
              cssClass='z-20'
            />
          </div>
          <Search setSearch={setSearch} search={search} />
        </div>
        <div className='sm:px-6 lg:px-8'>
          <div className='flex flex-col'>
            <div className='min-w-full overflow-hidden'>
              <div className='shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                <div className='table-wrap block custom-scroll overflow-x-auto'>
                  {isLoading ? (
                    <div
                      className={`flex flex-col w-full min-h-screen bg-gray-50 items-center justify-center`}
                    >
                      <CircularLoader
                        classes='flex justify-center items-center'
                        classloader='loader-l'
                      />
                    </div>
                  ) : (
                    <table className='min-w-full divide-y divide-gray-300 table-fixed'>
                      <thead
                        style={{ top: "0px" }}
                        className='bg-gray-50 sticky z-[5]'
                      >
                        <tr>
                          <TableHeader
                            cssClass='w-[20%]'
                            sortName='street_address'
                            flipSort={flipSort}
                            sortBy={sortBy}
                            isSort={false}
                            sortDescending={sortDescending}
                            label={"Property Address"}
                          />

                          <TableHeader
                            cssClass='sm:pl-3 w-[20%] text-center'
                            isSort={false}
                            sortName='participants'
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                            label={"Participants"}
                          />

                          {/*<TableHeader
                            cssClass='sm:pl-3 w-[20%] text-center'
                            isSort={false}
                            sortName='workers'
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                            label={"Workers"}
                          />*/}

                          <TableHeader
                            cssClass='w-[20%] text-center'
                            isSort={false}
                            sortName='region'
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                            label={"Region"}
                          />
                          <TableHeader
                            cssClass='sm:pl-3 w-[10%] text-center'
                            isSort={false}
                            sortName='room'
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                            label={"Vacancy"}
                          />
                          <TableHeader
                              cssClass='sm:pl-3 w-[10%] text-center'
                              isSort={false}
                              sortName='room'
                              flipSort={flipSort}
                              sortBy={sortBy}
                              sortDescending={sortDescending}
                              label={"Status"}
                          />
                          <th
                            scope='col'
                            className='text-gray-900 py-3.5 pl-3 pr-4 text-left bg-blue-50 text-sm font-semibold w-[10%]'
                          >
                            Actions
                            <span className='sr-only'>View</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className='divide-y divide-gray-200 bg-white overflow-y-auto'>
                        {silAccommodations.map((home, index) => (
                          <SilAccommodationTableItem
                            key={index}
                            index={index}
                            home={home}
                          />
                        ))}
                        {renderLoading === LOADER_STATUS.COMPLATE &&
                          silAccommodations?.length === 0 && (
                            <tr>
                              <td
                                colSpan={7}
                                className='py-6 text-center text-red-600'
                              >
                                No data found!
                              </td>
                            </tr>
                          )}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='bg-white sm:px-6 lg:px-8 max-w-7xl mx-auto py-4'>
        <Pagination
          page={paginationObj.page}
          activeListLength={silAccommodations}
          limit={SP_PARTICIPANTS_LIST_LIMIT}
          totalList={paginationObj.total}
          handlePagination={(e) => {
            const obj = paginationObj;
            obj.page = e;
            setPaginationObj({ ...obj });
            handleFetch({}, sortBy);
          }}
          totalPages={paginationObj.totalPages}
          cssClass='!m-0'
        />
      </div>
    </>
  );
};

export default SilAccommodationTable;
