import React, { useEffect, useState } from "react";
import { apiGetRequest } from "helpers/Requests";
import PaginationComponent from "../../common/Pagination/index";
import { getPaginationObj } from "Utils/utils";
import {
  emptyPaginationObj,
  SORT_TYPE,
  FIVE_PAGE_LIMIT,
  LOADER_STATUS,
  USER_ROLE,
} from "Constants/constant";
import SubmittedFormViewTable from "./SubmittedFormViewTable";
import _ from "lodash";
import { roleAccess } from "helpers/RolesPermission";

const SubmittedFormView = ({
  role = "crm",
  supportPlansId,
  participantsUid,
  title,
  subtabs,
  participantId,
  getSupportPlanFormsList,
  loading,
  supportPlanFormsList,
  resetSubmittedFormsList,
  parentSlug,
}) => {
  const [selectedPdfId, setSelectedPdfId] = useState("");
  const [renderLoading, setRenderLoading] = useState(LOADER_STATUS.NA);
  const [paginationObj, setPaginationObj] = useState(
    supportPlanFormsList
      ? getPaginationObj(
          supportPlanFormsList.submitted_forms,
          supportPlanFormsList.query_params
        )
      : emptyPaginationObj
  );
  const [sortBy, setSortBy] = useState(null);

  useEffect(() => {
    if (supportPlanFormsList) {
      setPaginationObj(
        getPaginationObj(
          supportPlanFormsList.submitted_forms,
          supportPlanFormsList.query_params
        )
      );
    }
  }, [supportPlanFormsList]);

  useEffect(() => {
    if (!loading && renderLoading === LOADER_STATUS.PENDING) {
      setRenderLoading(LOADER_STATUS.COMPLATE);
    }
  }, [loading]);

  useEffect(() => {
    if (supportPlanFormsList) {
      setPaginationObj(
        getPaginationObj(
          supportPlanFormsList.submitted_forms,
          supportPlanFormsList.query_params
        )
      );
    }
    return () => {
      if (resetSubmittedFormsList) {
        resetSubmittedFormsList();
      }
    };
  }, []);

  useEffect(() => {
    if (supportPlansId && participantsUid) {
      fetchFormTemplateList();
    }
  }, [supportPlansId]);

  const fetchFormTemplateList = async (filters) => {
  
    if (!filters || filters === undefined) {
      Object.assign((filters = {}), {
        page: paginationObj.page,
      });
    }
    Object.assign(filters, { per_page: FIVE_PAGE_LIMIT });
    let bodyFilter = filters.hasOwnProperty("filter") ? filters.filter : null;
    if (filters.hasOwnProperty("filter")) {
      delete filters.filter;
    }
    if (filters.is_complete === "both") {
      bodyFilter = null;
    }
    if (role !== "sil") {
      Object.assign(filters, { pt_id: participantsUid, c_id: supportPlansId });
    } else {
      Object.assign(filters, {
        home_id: participantsUid,
        c_id: supportPlansId,
      });
    }
    if (renderLoading === LOADER_STATUS.NA) {
      setRenderLoading(LOADER_STATUS.PENDING);
    }

    if (participantsUid) {
      getSupportPlanFormsList({
        role,
        filters,
        bodyFilter,
        pt_id: participantsUid,
        c_id: supportPlansId,
      });
    }
  };

  const refreshFormList = () => {
    fetchFormTemplateList(); // Call the function to refresh the list
  };

  const handleFetch = (obj, sortBy) => {
    if (sortBy) {
      Object.assign(obj, {
        sort_field: obj.hasOwnProperty("sort_field")
          ? obj.sort_field
          : paginationObj.sort_field
          ? paginationObj.sort_field
          : null,
        sort_order: obj.hasOwnProperty("sort_order")
          ? obj.sort_order
          : paginationObj.sort_order === SORT_TYPE.DESC
          ? SORT_TYPE.DESC
          : SORT_TYPE.ASC,
      });
    }
    if (paginationObj.page) {
      Object.assign(obj, { page: obj.page ? obj.page : paginationObj.page });
    }
    if (paginationObj.filter) {
      paginationObj.filter.hasOwnProperty("is_complete");
      Object.assign(obj, {
        filter: paginationObj.filter,
      });
    }
    if (paginationObj.sort_field && !obj.hasOwnProperty("sort_field")) {
      Object.assign(obj, {
        sort_field: sortBy,
        sort_order:
          paginationObj.sort_order === SORT_TYPE.DESC
            ? SORT_TYPE.DESC
            : SORT_TYPE.ASC,
      });
    }
    if (obj.hasOwnProperty("search")) {
      Object.assign(obj, { page: 1, search: obj.search });
    }
    if (paginationObj.search && !obj.hasOwnProperty("search")) {
      Object.assign(obj, { search: paginationObj.search });
    }
    if (obj.is_complete !== "both" && obj.hasOwnProperty("is_complete")) {
      Object.assign(obj, {
        filter: {
          is_complete: [obj.is_complete],
        },
      });
    }
    fetchFormTemplateList({ ...obj });
  };

  const handleDownload = async (item, role) => {
    try {
      setSelectedPdfId(item?.uuid);
      // const res = await apiGetRequest(
      //   `/sp/participant/pdf/service-agreement?form_data_uuid=${id}`
      // );
      // const endpoint = roleAccess([USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager])
      //   ? `/sp/participant/pdf/service-agreement?form_data_uuid=${id}`
      //   : `/hrm/participant/pdf/service-agreement?form_data_uuid=${id}`;

      let endpoint = "";

      if (roleAccess([USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager])) {
        if (item.title.toLowerCase() === "ndis service agreement form") {
          endpoint = `/sp/participant/pdf/service-agreement?form_data_uuid=${item?.uuid}`;
        } else {
          if (role === "hrm") {
            endpoint = `/sp/hrm-form_data/generate-pdf/${item?.uuid}`;
          } else if (role === "sil") {
            endpoint = `/sil/home/form/pdf/generation/${item?.uuid}`;
          } else {
            endpoint = `/sp/form_data/generate-pdf/${item?.uuid}`;
          }
        }
      } else if (roleAccess([USER_ROLE.Sp_Standard_User])) {
        if (item.title.toLowerCase() === "ndis service agreement form") {
          endpoint = `/hrm/participant/pdf/service-agreement?form_data_uuid=${item?.uuid}`;
        } else {
          if (role === "hrm") {
            endpoint = `/worker/hrm/submitted-forms/generate-pdf/${item?.uuid}`;
          }  else if (role === "sil") {
            endpoint = `/sil/home/form/pdf/generation/${item?.uuid}`;
          }
           else {
            endpoint = `/hrm/form_data/generate-pdf/${item?.uuid}`;
          }
        }
      }

      const res = await apiGetRequest(endpoint);

      if (res.status === 200) {
        window.open(res.data.data.download_url, "_blank");
        // const link = document.createElement("a");
        // link.href = res.data.data.download_url;
        // // link.setattribute("target", "_blank");
        // link.setAttribute("download", `FileName.pdf`);
        // document.body.appendChild(link);
        // link.click();
        // link.parentNode.removeChild(link);
      }
      setSelectedPdfId("");
    } catch (err) {
      setSelectedPdfId("");
      console.log(err);
    }
  };

  return (
    <div className='px-4'>
      {subtabs}
      <div className='flex flex-col'>
        <p className='text-blue-900 scrollbar-hide text-2xl font-bold pb-4'>
          {title}
        </p>
        <SubmittedFormViewTable
          role={role}
          parentSlug={parentSlug}
          templateList={
            supportPlanFormsList &&
            supportPlanFormsList?.submitted_forms &&
            supportPlanFormsList?.submitted_forms?.data &&
            supportPlanFormsList?.submitted_forms?.data?.length > 0
              ? supportPlanFormsList?.submitted_forms?.data
              : []
          }
          isLoading={loading}
          queryParams={paginationObj}
          handleDownload={handleDownload}
          handleFetch={handleFetch}
          getSortBy={(e) => setSortBy(e)}
          participantsUid={participantsUid}
          participantId={participantId}
          selectedPdfId={selectedPdfId}
          renderLoading={renderLoading}
          refreshFormList={refreshFormList}
        />
      </div>
      {!loading && (
        <div className='bg-white' style={{ marginTop: 0 }}>
          <PaginationComponent
            page={paginationObj.page}
            activeListLength={
              supportPlanFormsList?.submitted_forms
                ? supportPlanFormsList?.submitted_forms?.data
                : []
            }
            limit={FIVE_PAGE_LIMIT}
            totalList={paginationObj.total}
            handlePagination={(e) => {
              const data = _.cloneDeep(paginationObj);
              const obj = paginationObj;
              obj.page = e;
              setPaginationObj({ ...obj });
              if (data.page !== e) {
                handleFetch({}, sortBy);
              }
            }}
            totalPages={paginationObj.totalPages}
          />
        </div>
      )}
    </div>
  );
};

export default SubmittedFormView;
