import { Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { BsDownload, BsThreeDotsVertical } from "react-icons/bs";
import { HiOutlineTrash, HiOutlineEye } from "react-icons/hi";
import { MdDriveFileRenameOutline } from "react-icons/md";
import { DropdownItem } from "components/common/Enhansment/DropDown/DropdownMenu/DropdownItem";
import { RiGitRepositoryPrivateLine } from "react-icons/ri";
import { roleAccess } from "helpers/RolesPermission";
import {
  BLK_PORTAL_PREMISSION_KEYS_CHILDREN,
  PERMISSION_KEYS,
  PERMISSION_KEYS_CHILDREN,
} from "store/constants";
import { toast } from "react-toastify";
import { USER_ROLE } from "Constants/constant";
import GoogleDocsViewer from "components/common/GoogleDocsViewer";
import { baseURLImage } from "Utils/utils";

export const DropDown = (props) => {
  const {
    type,
    clickHandler,
    permissionRename,
    role,
    documents,
    isUserGracePeriodOver,
  } = props;

  const menuList = [];
  // const usewrType = localStorage.getItem("userType");
  if (
    roleAccess([
      USER_ROLE.Sp_Admin,
      USER_ROLE.Sp_manager,
      USER_ROLE.Sp_Standard_User,
    ])
  ) {
    if (
      !roleAccess([USER_ROLE.Sp_Standard_User]) ||
      (roleAccess([USER_ROLE.Sp_Standard_User]) &&
        type !== "file" &&
        role === null)
    ) {
      const remove = {
        label: "Remove",
        icon: <HiOutlineTrash className='icon-size' />,
      };
      menuList.unshift(remove);
    }

    if (
      role === "participant" &&
      type === "file" &&
      !roleAccess([USER_ROLE.Sp_Standard_User])
    ) {
      const access = {
        label: "Change Permission",
        icon: <RiGitRepositoryPrivateLine />,
      };
      menuList.unshift(access);
    }
  }

  if (type === "file") {
    const download = {
      label: "Download",
      icon: <BsDownload className='icon-size' />,
    };
    menuList.unshift(download);

    const view = {
      label: "View",
      icon: <HiOutlineEye className='icon-size' />,
    };
    menuList.unshift(view);
  } else if (!permissionRename) {
    const rename = {
      label: "Rename",
      icon: <MdDriveFileRenameOutline className='icon-size' />,
    };
    menuList.unshift(rename);
  }

  const handleClick = (e) => {
    if (e === "Download") {
      clickHandler(e);
      return;
    }

    if (e === "View") {
      clickHandler(e);
      return;
    }

    if (type === "file") {
      if (
        roleAccess(
          [USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager],
          PERMISSION_KEYS.PARTICIPANT,
          PERMISSION_KEYS_CHILDREN.PARTICIPANT.DOCUMEMT.UPDATE
        ) &&
        e === "Change Permission"
      ) {
        clickHandler(e);
      } else if (
        roleAccess(
          [],
          PERMISSION_KEYS.PARTICIPANT,
          PERMISSION_KEYS_CHILDREN.PARTICIPANT.DOCUMEMT.DELETE
        ) &&
        e === "Remove"
      ) {
        if (isUserGracePeriodOver) return;
        clickHandler(e);
      } else {
        toast("Don't have permission!");
      }
    } else if (type !== "file") {
      if (
        (roleAccess(
          [USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager],
          PERMISSION_KEYS.PARTICIPANT,
          PERMISSION_KEYS_CHILDREN.PARTICIPANT.DOCUMEMT.FOLDER.UPDATE
        ) ||
          roleAccess(
            [USER_ROLE.Sp_Standard_User],
            PERMISSION_KEYS.DOCUMENT,
            BLK_PORTAL_PREMISSION_KEYS_CHILDREN.DOCUMEMT.FOLDER.UPDATE
          )) &&
        e === "Rename"
      ) {
        clickHandler(e);
      } else if (
        (roleAccess(
          [USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager],
          PERMISSION_KEYS.PARTICIPANT,
          PERMISSION_KEYS_CHILDREN.PARTICIPANT.DOCUMEMT.FOLDER.DELETE
        ) ||
          roleAccess(
            [USER_ROLE.Sp_Standard_User],
            PERMISSION_KEYS.DOCUMENT,
            BLK_PORTAL_PREMISSION_KEYS_CHILDREN.DOCUMEMT.FOLDER.DELETE
          )) &&
        e === "Remove"
      ) {
        if (isUserGracePeriodOver) return;
        clickHandler(e);
      } else toast("Don't have permission!");
    }
  };

  return (
    <div className='max-w-56 text-right top-16 '>
      {menuList.length > 0 && (
        <Menu as='div' className='relative inline-block text-left'>
          <div>
            <Menu.Button className='inline-flex justify-center w-full  text-sm font-medium rounded-md bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'>
              <BsThreeDotsVertical />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
          >
            <Menu.Items className='absolute  right-4 w-56 mt-1 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[500]'>
              <div className='px-1 py-1 '>
                {menuList.map((item, index) => (
                  <DropdownItem
                    isUserGracePeriodOver={isUserGracePeriodOver}
                    key={index}
                    item={item}
                    type={type}
                    clickHandler={(e) => handleClick(e)}
                  />
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      )}
    </div>
  );
};
